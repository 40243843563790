define("ember-svg-jar/inlined/arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M12.55 1.4v21.84l-7.42-7.42c-.56-.56-1.4-.56-1.96 0s-.56 1.4 0 1.96l9.8 9.8c.56.56 1.4.56 1.96 0l9.8-9.8c.28-.28.42-.7.42-.98 0-.42-.14-.7-.42-.98-.56-.56-1.4-.56-1.96 0l-7.42 7.42V1.4c0-.84-.56-1.4-1.4-1.4-.84 0-1.4.7-1.4 1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});