define("ember-svg-jar/inlined/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M16.7 2l-1.946 2H10.58l-.294.3-9.252 9.423a3.257 3.257 0 00-.006 4.55l6.716 6.853a3.178 3.178 0 004.52.025l.443-.444.078-.078c.6.596 1.397.894 2.095.894.7 0 1.5-.3 2.1-.9L28 13.3V2H16.7zM5.68 13.323c-1.2 1.1-1.2 3 0 4.2l5.691 5.691-.078.079-.444.444a1.178 1.178 0 01-1.677-.01l-6.716-6.853c-.47-.48-.469-1.267.005-1.75L11.4 6h1.407zM17.5 4H26v8.5L15.7 23.162c-.2.3-.5.3-.7.3-.1 0-.4 0-.7-.3l-7.1-7.1c-.3-.2-.3-.5-.3-.7 0-.1 0-.4.3-.7L17.5 4zM20 8.3c-1.05 0-1.75.7-1.75 1.75s.7 1.75 1.75 1.75 1.75-.7 1.75-1.75S21.05 8.3 20 8.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});