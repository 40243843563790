define("ember-svg-jar/inlined/collaborations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M27.735 6.829l-2.683 1.888 1.035 3.166c.145.44.074.906-.194 1.277a1.445 1.445 0 01-1.164.598c-.301 0-.592-.096-.84-.278l-2.747-2.011-2.751 1.987c-.628.455-1.551.295-2-.326a1.406 1.406 0 01-.192-1.279l1.032-3.122-2.688-1.9a1.423 1.423 0 01-.537-1.598 1.422 1.422 0 011.36-.996h3.36L19.784.987c.195-.6.728-.987 1.358-.987.63 0 1.163.387 1.358.986l1.054 3.246 3.36.003c.624 0 1.171.4 1.36.996a1.422 1.422 0 01-.54 1.598zm-3.013 5.498l-1.024-3.16a1.42 1.42 0 01.535-1.612l2.682-1.89-3.357-.008c-.62 0-1.166-.396-1.358-.985l-1.054-3.246-1.064 3.245c-.191.59-.737.987-1.357.987h-3.359L18.05 7.56c.518.363.732 1.013.533 1.614l-1.033 3.123 2.753-1.982a1.42 1.42 0 01.837-.272c.306 0 .597.096.843.276l2.74 2.007zM8.267 9.679c.339 0 .639-.243.7-.588l.354-2.023a.712.712 0 00-1.402-.246l-.354 2.023a.712.712 0 00.702.834zm-2.152.929a.71.71 0 01-1.006 0L3.656 9.156A.711.711 0 114.662 8.15l1.453 1.452a.711.711 0 010 1.006zm2.452-.18c.969.107 1.894.865 2.069 1.614.09.388.137 1.192.107 1.59-.051.677-.45 2.68-.45 2.68l3.796-.001c.833 0 1.61.416 2.13 1.139.56.779.718 1.761.426 2.63l-2.471 4.933c-.354.988-1.33 1.97-2.54 1.97H4.722A1.109 1.109 0 013.62 28H1.137c-.308 0-.607-.127-.823-.35A1.083 1.083 0 010 26.86c.041-1.461.017-7.64 0-9.199-.003-.298.111-.58.32-.792.211-.212.492-.33.792-.33l3.331-.016c.346-.928 1.195-3.228 1.409-4.002.362-1.31 1.462-2.236 2.715-2.092zM1.43 26.578h1.66v-8.616H1.426c.016 1.824.033 6.835.004 8.615zm13.632-8.297c-.249-.347-.605-.547-.974-.547h-5.43l.673-4.844a1.043 1.043 0 00-.922-.969c-.679-.083-1.052.495-1.185.978-.235.85-1.26 3.614-1.62 4.577a.601.601 0 01-.48.386l-.392.056v7.643h6.9c.536 0 1.036-.536 1.21-1.053l2.467-4.925c.125-.42.032-.914-.247-1.302z\"/>",
    "attrs": {
      "width": "29",
      "height": "28",
      "viewBox": "0 0 29 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});