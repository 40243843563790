define("ember-svg-jar/inlined/hint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M.375 14c0-.621.504-1.125 1.125-1.125h1.667a1.125 1.125 0 010 2.25H1.5A1.125 1.125 0 01.375 14zm3.99-9.635a1.126 1.126 0 011.591 0l1.18 1.18a1.125 1.125 0 11-1.592 1.59L4.365 5.956a1.125 1.125 0 010-1.59zM14 .375c.621 0 1.125.504 1.125 1.125v1.667a1.125 1.125 0 01-2.25 0V1.5c0-.621.504-1.125 1.125-1.125zm9.635 3.99c.439.44.439 1.152 0 1.591l-1.18 1.18a1.125 1.125 0 01-1.59-1.592l1.179-1.179a1.124 1.124 0 011.59 0zM23.708 14c0-.621.504-1.125 1.125-1.125H26.5a1.125 1.125 0 010 2.25h-1.667A1.125 1.125 0 0123.708 14zM5.375 14A8.625 8.625 0 0114 5.375 8.625 8.625 0 0122.625 14a8.608 8.608 0 01-4.167 7.375V25.5a2.125 2.125 0 01-2.125 2.125h-4.666A2.125 2.125 0 019.542 25.5v-4.125A8.608 8.608 0 015.375 14zM14 7.625A6.375 6.375 0 007.625 14a6.366 6.366 0 003.543 5.703l.624.31v5.362h4.416v-5.361l.624-.31A6.366 6.366 0 0020.375 14 6.375 6.375 0 0014 7.625z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M9.542 22.042h8.916v2.25H9.542v-2.25z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});