define("ember-svg-jar/inlined/instant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.882 7.159l-2.047 4.986 3.615 1.003.113.026c.285.118.5.363.578.662.074.301 0 .62-.198.857l-6.6 7.72a.725.725 0 01-1.227-.74l2.05-4.996-3.616-1.012-.113-.026a.989.989 0 01-.575-.662c-.074-.3 0-.619.198-.857l6.59-7.706a.73.73 0 011.232.745zM13.998 0a1.445 1.445 0 000 2.89c6.134.005 11.105 4.975 11.112 11.108a1.445 1.445 0 102.89 0C27.992 6.27 21.727.007 13.998 0zm-1.464 26.55a1.444 1.444 0 001.44 1.447 1.444 1.444 0 10-1.443-1.447h.003zM1.444 15.433a1.444 1.444 0 100-2.887 1.444 1.444 0 000 2.887zm5.537 10.68a1.444 1.444 0 10-.525-1.974 1.45 1.45 0 00.525 1.975zM6.475 3.849a1.444 1.444 0 102.49-1.459 1.444 1.444 0 00-2.49 1.46zM2.399 19.011a1.447 1.447 0 101.974.531 1.447 1.447 0 00-1.974-.528v-.003zm22.456 2.73a1.444 1.444 0 10-.722-.197c.219.13.468.2.722.2v-.003zM3.851 6.465A1.444 1.444 0 102.41 8.966a1.444 1.444 0 001.442-2.5zm15.163 19.14c.258.448.736.724 1.253.724.249-.004.492-.073.706-.2a1.444 1.444 0 10-1.97-.53l.011.005z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});