define("ember-svg-jar/inlined/campaign-find", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.466 2.255v7.333H23.8V2.255h-7.333zM15.213 0c-.553 0-1.002.449-1.002 1.002v9.839c0 .553.45 1.002 1.002 1.002h9.84c.553 0 1.001-.449 1.001-1.002V1.002C26.054.45 25.606 0 25.052 0h-9.838zM2.255 2.256V9.59h7.333V2.256H2.255zM1.002.002C.45.002 0 .45 0 1.004v9.838c0 .554.449 1.002 1.002 1.002h9.839c.553 0 1.002-.448 1.002-1.002V1.004c0-.554-.449-1.002-1.002-1.002H1.002zm1.253 23.324v-7.334h7.333v7.334H2.255zM0 14.74c0-.554.449-1.002 1.002-1.002h9.839c.553 0 1.002.448 1.002 1.002v9.838c0 .554-.449 1.003-1.002 1.003H1.002A1.002 1.002 0 010 24.578V14.74zm19.686 8.64a3.694 3.694 0 100-7.388 3.694 3.694 0 000 7.388zm4.697-.044a5.949 5.949 0 10-1.7 1.489l2.393 2.392a1.127 1.127 0 101.594-1.594l-2.287-2.287z\"/>",
    "attrs": {
      "width": "27",
      "height": "28",
      "viewBox": "0 0 27 28",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});