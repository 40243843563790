define("@latermedia/ember-later-analytics/components/ui/media-kit/about-section", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <p class={{if this.shouldShowExpandedDescription "cCI--decription cCI--decription--showMore" "cCI--decription"}}>
    {{@about}}
  </p>
  
  {{#if this.displayShowMore}}
    {{! Toggle to expand collapse description }}
    <a {{on "click" this.toggleDescription}} class="cCI--decriptionLink">
      {{#if this.shouldShowExpandedDescription}}
        {{t "media_kit.about.show_less" locale="en-us"}}
        {{svg-jar "nav-left" class="o--icon--xxs"}}
      {{else}}
        {{t "media_kit.about.show_less" locale="en-us"}}
        {{svg-jar "nav-right" class="o--icon--xxs"}}
      {{/if}}
    </a>
  {{/if}}
  
  */
  {
    "id": "MwTdyjFG",
    "block": "[[[10,2],[15,0,[52,[30,0,[\"shouldShowExpandedDescription\"]],\"cCI--decription cCI--decription--showMore\",\"cCI--decription\"]],[12],[1,\"\\n  \"],[1,[30,1]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"displayShowMore\"]],[[[1,\"  \"],[11,3],[24,0,\"cCI--decriptionLink\"],[4,[38,1],[\"click\",[30,0,[\"toggleDescription\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"shouldShowExpandedDescription\"]],[[[1,\"      \"],[1,[28,[35,2],[\"media_kit.about.show_less\"],[[\"locale\"],[\"en-us\"]]]],[1,\"\\n      \"],[1,[28,[35,3],[\"nav-left\"],[[\"class\"],[\"o--icon--xxs\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,2],[\"media_kit.about.show_less\"],[[\"locale\"],[\"en-us\"]]]],[1,\"\\n      \"],[1,[28,[35,3],[\"nav-right\"],[[\"class\"],[\"o--icon--xxs\"]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@about\"],false,[\"if\",\"on\",\"t\",\"svg-jar\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/media-kit/about-section.hbs",
    "isStrictMode": false
  });

  const ABOUT_LENGTH_TOGGLE_MIN = 190;
  let AboutSectionComponent = (_class = class AboutSectionComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "shouldShowExpandedDescription", _descriptor, this);
    }

    toggleDescription() {
      this.shouldShowExpandedDescription = !this.shouldShowExpandedDescription;
    }

    get characterLength() {
      return this.args?.about?.length;
    }

    get displayShowMore() {
      return this.characterLength > ABOUT_LENGTH_TOGGLE_MIN;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "shouldShowExpandedDescription", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleDescription", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDescription"), _class.prototype)), _class);
  _exports.default = AboutSectionComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AboutSectionComponent);
});