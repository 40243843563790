define("ember-svg-jar/inlined/thumbs-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M24.1 3.742a1.695 1.695 0 00-1.134-.409h-2.133v10.834h2.133a1.695 1.695 0 001.7-1.409V4.742a1.695 1.695 0 00-.566-1zm-5.267 11.212l-4.282 9.636a2.5 2.5 0 01-1.884-2.423V17.5a1 1 0 00-1-1H5.053a1.332 1.332 0 01-1.334-1.533l1.61-10.5a1.333 1.333 0 011.333-1.134h12.171v11.621zm4.107-13.62a3.695 3.695 0 013.718 3.199.994.994 0 01.009.134v8.166c0 .045-.003.09-.01.134a3.695 3.695 0 01-3.717 3.2h-2.457l-4.402 9.906a1 1 0 01-.914.594 4.5 4.5 0 01-4.5-4.5V18.5H5.069a3.335 3.335 0 01-3.328-3.833l1.61-10.5A3.336 3.336 0 016.68 1.333h16.26z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});