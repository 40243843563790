define("@latermedia/ember-later-analytics/models/report-meta", ["exports", "@latermedia/ember-later-analytics/utils/time-methods"], function (_exports, _timeMethods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ReportMetaModel {
    constructor(meta) {
      _defineProperty(this, "timestamp", void 0);

      _defineProperty(this, "startTime", void 0);

      _defineProperty(this, "endTime", void 0);

      _defineProperty(this, "startTimeUnix", void 0);

      _defineProperty(this, "endTimeUnix", void 0);

      _defineProperty(this, "reportDurationInDays", void 0);

      _defineProperty(this, "userTimeZone", void 0);

      _defineProperty(this, "userTimeZoneStandard", void 0);

      _defineProperty(this, "userTimeZoneShort", void 0);

      _defineProperty(this, "keenWriteKey", void 0);

      _defineProperty(this, "errors", void 0);

      this.timestamp = meta?.timestamp;
      this.startTimeUnix = meta?.start_time;
      this.startTime = (0, _timeMethods.unixToDate)(meta?.start_time);
      this.endTimeUnix = meta?.end_time;
      this.endTime = (0, _timeMethods.unixToDate)(meta?.end_time);
      this.reportDurationInDays = (0, _timeMethods.daysBetweenTimes)(this.startTimeUnix, this.endTimeUnix);
      this.userTimeZone = meta?.user_time_zone || 'America/New_York';
      this.userTimeZoneStandard = meta?.user_time_zone_standard || 'America/New_York';
      this.userTimeZoneShort = (0, _timeMethods.convertTimezoneLongToShort)(this.userTimeZoneStandard);
      this.keenWriteKey = meta?.keenWriteKey;
      this.errors = meta?.errors;
    }

  }

  _exports.default = ReportMetaModel;
});