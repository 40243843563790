define("ember-svg-jar/inlined/pause", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8.667 1.5C6.306 1.5 4.5 3.306 4.5 5.667v16.666c0 2.361 1.806 4.167 4.167 4.167 2.36 0 4.166-1.806 4.166-4.167V5.667c0-2.361-1.805-4.167-4.166-4.167zm1.389 20.833c0 .834-.556 1.39-1.39 1.39-.833 0-1.388-.556-1.388-1.39V5.667c0-.834.555-1.39 1.389-1.39.833 0 1.389.556 1.389 1.39v16.666zM19.778 1.5c-2.361 0-4.167 1.806-4.167 4.167v16.666c0 2.361 1.806 4.167 4.167 4.167 2.36 0 4.166-1.806 4.166-4.167V5.667c0-2.361-1.805-4.167-4.166-4.167zm1.389 20.833c0 .834-.556 1.39-1.39 1.39-.833 0-1.388-.556-1.388-1.39V5.667c0-.834.555-1.39 1.389-1.39.833 0 1.389.556 1.389 1.39v16.666z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});