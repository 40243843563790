define("ember-svg-jar/inlined/help-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14 .5C6.544.5.5 6.544.5 14S6.544 27.5 14 27.5 27.5 21.456 27.5 14A13.46 13.46 0 0014 .5zm.66 20.34a1.27 1.27 0 01-.9.38 1.32 1.32 0 01-1.33-1.4c-.01-.37.127-.73.38-1 .244-.264.59-.41.95-.4.35-.006.686.14.92.4.253.27.39.63.38 1a1.32 1.32 0 01-.4 1.02zm3.27-8.62a4.31 4.31 0 01-.72 1c-.27.28-.77.77-1.49 1.45l-.47.51a2.34 2.34 0 00-.27.4 1.63 1.63 0 00-.13.37c0 .12-.08.33-.15.63-.11.65-.45 1-1 1a1 1 0 01-.76-.31 1.31 1.31 0 01-.31-.95 3.67 3.67 0 01.19-1.32 3.51 3.51 0 01.6-1c.25-.29.59-.63 1-1 .41-.37.64-.61.81-.78.16-.182.3-.38.42-.59.111-.22.17-.463.17-.71a1.68 1.68 0 00-.52-1.26 1.83 1.83 0 00-1.3-.58 1.79 1.79 0 00-1.41.51 4.19 4.19 0 00-.77 1.53c-.2.7-.58 1.05-1.13 1.05a1.05 1.05 0 01-.82-.37 1.13 1.13 0 01-.37-.8 3.61 3.61 0 01.5-1.79 4.33 4.33 0 011.56-1.5 4.6 4.6 0 012.44-.6 4.82 4.82 0 012.23.5A3.6 3.6 0 0117.7 9a3.49 3.49 0 01.53 1.86 3.06 3.06 0 01-.3 1.36z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});