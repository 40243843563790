define("ember-svg-jar/inlined/shoppable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M27.746 5.352a1.118 1.118 0 00-.884-.401h-20.7L5.81 2.917C5.72 2.372 5.219 2 4.659 2h-3.51C.502 2 0 2.516 0 3.117c0 .602.53 1.118 1.15 1.118h2.506l3.037 16.759c.089.544.59.917 1.15.917H9.23c-.649.343-1.09 1.002-1.09 1.776 0 1.117.913 2.005 2.063 2.005 1.15 0 2.064-.888 2.064-2.005 0-.774-.442-1.433-1.09-1.776h9.14c-.649.343-1.091 1.002-1.091 1.776 0 1.117.914 2.005 2.064 2.005s2.064-.888 2.064-2.005c0-.774-.442-1.433-1.091-1.776h1.209c.648 0 1.15-.516 1.15-1.118 0-.63-.531-1.117-1.15-1.117H8.787l-.354-1.92h16.512c.56 0 1.032-.4 1.15-.916l1.887-10.543a1.19 1.19 0 00-.236-.945zm-3.774 10.141H8.05L6.546 7.185h18.93l-1.504 8.308z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});