define("ember-svg-jar/inlined/up-arrow-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title/><path d=\"M16 13.29l-1.41 1.42L12 12.12l-2.58 2.59L8 13.29l4-4zM19 5H5v14h14V5m1-2a1 1 0 011 1v16a1 1 0 01-1 1H4a1 1 0 01-1-1V4a1 1 0 011-1z\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});