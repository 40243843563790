define("@latermedia/ember-later-analytics/components/ui/meter-list", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class="cCH--chartList" data-test-id="meter-list">
    {{#each @meterList as |meterItem|}}
      <li class="cCH--chartList__item">
        <span class={{concat "cCH--chartList__title " @titleClass}}>
          {{meterItem.title}}
        </span>
        <div class={{concat "cCH--barChart " @chartClass}}>
          <div 
            class="cCH--barChart__unit" 
            style={{local-html-safe (t "formatters.style_width" percentage_of_width=meterItem.percentageOfWidth)}}
          >
          </div>
        </div>
        <span class="cCH--chartList__value">
          {{t "formatters.percentage" percentage=meterItem.percentageOfWidth}}
        </span>
      </li>
    {{/each}}
  </ul>
  
  */
  {
    "id": "tPN6FFCB",
    "block": "[[[10,\"ul\"],[14,0,\"cCH--chartList\"],[14,\"data-test-id\",\"meter-list\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[14,0,\"cCH--chartList__item\"],[12],[1,\"\\n      \"],[10,1],[15,0,[28,[37,2],[\"cCH--chartList__title \",[30,3]],null]],[12],[1,\"\\n        \"],[1,[30,2,[\"title\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[15,0,[28,[37,2],[\"cCH--barChart \",[30,4]],null]],[12],[1,\"\\n        \"],[10,0],[14,0,\"cCH--barChart__unit\"],[15,5,[28,[37,3],[[28,[37,4],[\"formatters.style_width\"],[[\"percentage_of_width\"],[[30,2,[\"percentageOfWidth\"]]]]]],null]],[12],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"cCH--chartList__value\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"formatters.percentage\"],[[\"percentage\"],[[30,2,[\"percentageOfWidth\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"@meterList\",\"meterItem\",\"@titleClass\",\"@chartClass\"],false,[\"each\",\"-track-array\",\"concat\",\"local-html-safe\",\"t\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/meter-list.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});