define("@latermedia/ember-later-analytics/components/stats-tile-grid", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    {{#each this.tileRows as |rowValues|}}
      <div class="cCH--statGrid">
        {{#each rowValues as |item|}}
          {{#if item}}
            <StatsTileItem
              @name={{item.name}}
              @main={{item.main}}
              @tooltip={{item.main.tooltip}}
              @significantDigits={{@significantDigits}}
              @trend={{null}}
              @additionalClasses=""
              @isSmall={{false}}
            >
              {{#if has-block}}
                {{yield}}
              {{else}}
                {{svg-jar "help" class="o--icon--sm o--icon--light"}}
              {{/if}}
            </StatsTileItem>
          {{else}}
            <StatsTileItem @isEmpty={{true}} />
          {{/if}}
        {{/each}}
      </div>
    {{/each}}
  </div>
  
  */
  {
    "id": "P2+lMLgo",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"tileRows\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"cCH--statGrid\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[41,[30,3],[[[1,\"          \"],[8,[39,3],null,[[\"@name\",\"@main\",\"@tooltip\",\"@significantDigits\",\"@trend\",\"@additionalClasses\",\"@isSmall\"],[[30,3,[\"name\"]],[30,3,[\"main\"]],[30,3,[\"main\",\"tooltip\"]],[30,4],null,\"\",false]],[[\"default\"],[[[[1,\"\\n\"],[41,[33,4],[[[1,\"              \"],[18,5,null],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[28,[35,6],[\"help\"],[[\"class\"],[\"o--icon--sm o--icon--light\"]]]],[1,\"\\n\"]],[]]],[1,\"          \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[39,3],null,[[\"@isEmpty\"],[true]],null],[1,\"\\n\"]],[]]]],[3]],null],[1,\"    \"],[13],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"rowValues\",\"item\",\"@significantDigits\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"stats-tile-item\",\"has-block\",\"yield\",\"svg-jar\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/stats-tile-grid.hbs",
    "isStrictMode": false
  });

  const generateRows = (tiles, numberOfColumns) => {
    if ((0, _utils.isEmpty)(tiles) || !numberOfColumns) {
      return [];
    }

    const numberOfRows = Math.ceil(tiles.length / numberOfColumns);
    const emptyRows = Array(numberOfRows).fill(null);
    const requiredTileLength = numberOfColumns * numberOfRows;
    const allTiles = [...tiles, ...Array(requiredTileLength - tiles.length).fill(null)];
    return emptyRows.map((_row, columnIndex) => {
      const startingIndex = columnIndex * numberOfColumns;
      return allTiles.slice(startingIndex, startingIndex + numberOfColumns);
    });
  };

  class StatsTileGrid extends _component2.default {
    get tileRows() {
      const tileValues = Object.values(this.args.tiles);
      return generateRows(tileValues, this.args.numberOfColumns);
    }

  }

  _exports.default = StatsTileGrid;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StatsTileGrid);
});