define("ember-svg-jar/inlined/collect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M7.825 16.801c.975 2.492 3.467 4.334 6.175 4.334 2.71 0 5.2-1.842 6.067-4.334h4.766v7.584c0 .65-.433 1.084-1.083 1.084H4.25c-.65 0-1.083-.542-1.083-1.084V16.8h4.658zM14.001 1c.65 0 1.082.434 1.082 1.084v9.602L18.66 8.11a1.046 1.046 0 011.516 0 1.046 1.046 0 010 1.516l-5.416 5.418a1.174 1.174 0 01-.758.324c-.218 0-.542-.108-.76-.324L7.825 9.626a1.046 1.046 0 010-1.516 1.046 1.046 0 011.516 0l3.576 3.576V2.084c0-.65.434-1.084 1.084-1.084zm8.867 9.502v3.034l1.1 1.099h-5.635c0 2.166-1.95 4.334-4.333 4.334S9.667 16.8 9.667 14.635H4.033l1.502-1.501V10.1L1 14.635v9.75c0 1.842 1.517 3.25 3.25 3.25h19.5c1.842 0 3.25-1.408 3.25-3.25v-9.75l-4.132-4.133z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});