define("@latermedia/ember-later-analytics/helpers/format-number-with-suffix", ["exports", "@latermedia/ember-later-analytics/utils/format-number-with-suffix", "@ember/component/helper"], function (_exports, _formatNumberWithSuffix, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatNumberWithSuffixHelper = formatNumberWithSuffixHelper;

  /**
   * @module Helpers
   */

  /**
   * @class FormatNumberWithSuffix
   * @extends Helpers
   */

  /**
   * Converts count to use metric suffix symbols.
   *
   * If you are getting an error in here, you might
   * be passing in a string representation of a number.
   *
   * Using unary plus here to convert to number
   * see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Arithmetic_Operators#Unary_plus_()
   *
   * @method formatNumberWithSuffix
   * @param {Number} count Number to be formatted
   * @param {Number} [roundTo=0] (Optional) Number of decimal places to round to for a count between 10,000-1,000,000
   *
   * @return {String} Converted value that uses metric symbol presentation
   */
  function formatNumberWithSuffixHelper(_ref) {
    let [num, roundTo = 0] = _ref;
    return (0, _formatNumberWithSuffix.default)(num, roundTo);
  }

  var _default = (0, _helper.helper)(formatNumberWithSuffixHelper);

  _exports.default = _default;
});