define("@latermedia/ember-later-analytics/helpers/is-no-data", ["exports", "@ember/component/helper", "@latermedia/ember-later-analytics/utils/state-types"], function (_exports, _helper, _stateTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(_ref => {
    let [state] = _ref;
    return state?.type === _stateTypes.NO_DATA;
  });

  _exports.default = _default;
});