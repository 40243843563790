define("ember-svg-jar/inlined/ml", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M18.667 25.667V24H21v1.667A2.333 2.333 0 0118.667 28H2.333A2.333 2.333 0 010 25.667V9.333A2.333 2.333 0 012.333 7H4v2.333H2.333v16.334h16.334zM24.864 0H8.136C6.36 0 5 1.36 5 3.136v16.728C5 21.64 6.36 23 8.136 23h16.728C26.64 23 28 21.64 28 19.864V3.136C28 1.36 26.64 0 24.864 0zm1.045 19.864c0 .627-.418 1.045-1.045 1.045H11.69l8.99-8.99 5.228 5.226v2.719zm0-5.646l-5.227-5.227L8.764 20.909h-.628c-.627 0-1.045-.418-1.045-1.045V3.136c0-.627.418-1.045 1.045-1.045h16.728c.627 0 1.045.418 1.045 1.045v11.082zM12.32 4.182c-1.778 0-3.137 1.359-3.137 3.136 0 1.777 1.359 3.137 3.136 3.137 1.777 0 3.137-1.36 3.137-3.137s-1.36-3.136-3.137-3.136zm0 4.182c-.628 0-1.046-.419-1.046-1.046s.418-1.045 1.045-1.045 1.046.418 1.046 1.045-.419 1.046-1.046 1.046z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});