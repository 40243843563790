define("ember-code-snippet/-private/extension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getExtension;

  function getExtension(name) {
    let m = /\.(\w+)$/i.exec(name);
    return m ? m[1].toLowerCase() : undefined;
  }
});