define("ember-svg-jar/inlined/thumbs-up-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M11.92 1.927a1 1 0 01.914-.594 4.5 4.5 0 014.5 4.5V9.5h5.598a3.333 3.333 0 013.327 3.833l-1.61 10.5a3.335 3.335 0 01-3.327 2.834H4.667a3.333 3.333 0 01-3.333-3.334v-8.166a3.333 3.333 0 013.333-3.334h2.85l4.403-9.906z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});