define("ember-svg-jar/inlined/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M17.167 0l8.166 8.167v3.832H23V10.5h-8.167V2.333H5.5c-.415 0-.645.185-.773.308l-.044.042c-.116.117-.35.35-.35.817v21c0 .415.185.645.308.773l.042.044c.117.116.35.35.817.35l6.5-.001V28h9.867H5.5C3.517 28 2 26.483 2 24.5v-21C2 1.517 3.517 0 5.5 0h11.667zm0 3.267v4.9h4.9l-4.9-4.9z\"/><path d=\"M23.375 14.273c.188-.676.813-1.106 1.5-.983.688.185 1.125.799 1 1.474l-.375 1.597h1c.688 0 1.25.553 1.25 1.228 0 .676-.563 1.229-1.25 1.229H25l-.75 3.685h1c.688 0 1.25.552 1.25 1.228 0 .675-.563 1.228-1.25 1.228h-1.438l-.437 2.088c-.125.553-.688.983-1.25.983h-.25c-.688-.184-1.125-.798-1-1.474l.375-1.597h-3.688l-.437 2.088c-.125.553-.688.983-1.25.983h-.25c-.688-.184-1.125-.798-1-1.474L15 24.959h-1c-.688 0-1.25-.553-1.25-1.228 0-.676.563-1.228 1.25-1.228h1.5l.75-3.685h-1c-.688 0-1.25-.553-1.25-1.229 0-.675.563-1.228 1.25-1.228h1.5l.375-2.088c.188-.676.813-1.106 1.5-.983.688.185 1.125.799 1 1.474l-.313 1.597H23zm-.875 4.545h-3.688l-.75 3.685h3.688l.75-3.685z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});