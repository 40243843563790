define("ember-svg-jar/inlined/activate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M14 .966c-.375 0-.701.135-.966.4-.266.266-.4.592-.4.967v11.666c0 .376.134.701.4.967.265.265.59.4.966.4.375 0 .701-.135.966-.4.266-.265.4-.591.4-.967V2.333c0-.375-.134-.701-.4-.966a1.324 1.324 0 00-.966-.4zm8.734 6.152h-.015a11.724 11.724 0 012.212 3.432c.624 1.47.936 3.01.936 4.616 0 1.607-.312 3.146-.936 4.617a11.736 11.736 0 01-2.54 3.773 11.756 11.756 0 01-3.774 2.54c-1.47.625-3.01.937-4.616.937-1.607 0-3.146-.312-4.617-.937a11.735 11.735 0 01-3.773-2.54 11.756 11.756 0 01-2.54-3.773 11.702 11.702 0 01-.937-4.617c0-1.606.312-3.145.937-4.616.6-1.422 1.446-2.68 2.54-3.773.263-.263.587-.396.962-.396.374 0 .7.133.969.394.269.262.406.587.407.963 0 .377-.137.704-.405.971a9.19 9.19 0 00-1.963 2.9 9.166 9.166 0 00-.713 3.558c0 1.23.237 2.417.713 3.559a9.19 9.19 0 001.963 2.899 9.191 9.191 0 002.899 1.962 9.167 9.167 0 003.558.714c1.231 0 2.417-.238 3.559-.714a9.192 9.192 0 002.899-1.962 9.193 9.193 0 001.962-2.9 9.167 9.167 0 00.714-3.558c0-1.23-.238-2.417-.714-3.559A9.193 9.193 0 0020.46 8.71a1.331 1.331 0 01-.405-.97c0-.376.137-.701.407-.964.268-.261.595-.394.969-.394s.698.133.961.396l.343.341z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});