define("ember-svg-jar/inlined/notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#a)\"><path fill-rule=\"evenodd\" d=\"M15.459 18.834l11.916 6.62V.63L15.459 7.25H6.417C3.229 7.25.625 9.854.625 13.042c0 3.188 2.604 5.792 5.792 5.792h.538l4.337 7.048.004.006c.899 1.438 3.035 2.02 4.546.887 1.388-1.04 1.764-2.97.862-4.413l-.009-.014-2.343-3.514h1.107zm-3.81 0H9.596l3.609 5.864c.27.427.93.544 1.286.277.474-.355.568-.984.311-1.409l-3.155-4.732zM16.041 9.5l9.083-5.046V21.63l-9.084-5.046H6.417a3.555 3.555 0 01-3.542-3.542A3.555 3.555 0 016.417 9.5h9.624z\" clip-rule=\"evenodd\"/></g><defs><clipPath id=\"a\"><path d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});