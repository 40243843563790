define("@latermedia/ember-later-analytics/models/analytics-social-profile", ["exports", "@latermedia/ember-later-analytics/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AnalyticsSocialProfileModel {
    constructor(socialProfile) {
      _defineProperty(this, "platform", 'Instagram');

      _defineProperty(this, "defaultAvatarUrl", _constants.PLACEHOLDER_URL);

      _defineProperty(this, "defaultNickname", '');

      _defineProperty(this, "reportSlug", void 0);

      _defineProperty(this, "avatarUrl", void 0);

      _defineProperty(this, "profileUID", void 0);

      this.reportSlug = socialProfile?.nickname || this.defaultNickname;
      this.avatarUrl = socialProfile?.avatar_url || this.defaultAvatarUrl;
      this.profileUID = socialProfile?.uid;
    }

  }

  _exports.default = AnalyticsSocialProfileModel;
});