define("ember-svg-jar/inlined/ai-sparkle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.33 6.06L11.903.435c-.147-.58-.983-.58-1.13 0L9.345 6.06a2.315 2.315 0 01-1.697 1.673L1.942 9.139c-.59.145-.59.97 0 1.115l5.707 1.406a2.315 2.315 0 011.697 1.672l1.426 5.625c.148.58.984.58 1.131 0l1.427-5.625a2.315 2.315 0 011.697-1.672l5.707-1.406c.59-.145.59-.97 0-1.115l-5.707-1.406A2.315 2.315 0 0113.33 6.06zm8.91 14.18l-.613-2.42c-.124-.489-.83-.489-.954 0l-.614 2.42a1.954 1.954 0 01-1.432 1.411l-2.455.605a.483.483 0 000 .941l2.455.605a1.954 1.954 0 011.432 1.411l.613 2.42c.125.49.83.49.955 0l.614-2.42a1.953 1.953 0 011.431-1.411l2.455-.605a.483.483 0 000-.94l-2.455-.606a1.953 1.953 0 01-1.431-1.41z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});