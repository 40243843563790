define("ember-svg-jar/inlined/clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title/><path d=\"M12 23a11 11 0 1111-11 11 11 0 01-11 11zm0-20a9 9 0 109 9 9 9 0 00-9-9z\"/><path d=\"M15 16a1 1 0 01-.71-.29l-3-3A1 1 0 0111 12V6a1 1 0 012 0v5.59l2.71 2.7a1 1 0 010 1.42A1 1 0 0115 16z\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});