define("ember-svg-jar/inlined/like", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M25.905 4.095c-2.794-2.793-7.41-2.793-10.204 0L14 5.796l-1.7-1.7c-2.795-2.794-7.41-2.794-10.205 0-2.793 2.793-2.793 7.41 0 10.203L3.796 16 14 26.204 24.204 16l1.7-1.7c2.794-2.795 2.794-7.29 0-10.205zm-4.98 12.026l-6.804 6.803-6.802-6.803-3.401-3.4c-1.823-1.944-1.823-4.981 0-6.803 1.822-1.944 4.859-1.944 6.802 0l3.401 3.4 3.402-3.4c1.822-1.944 4.859-1.944 6.802 0 1.823 1.943 1.823 4.98 0 6.802l-3.4 3.401z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});