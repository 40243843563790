define("ember-svg-jar/inlined/lib-lock-closed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M22.327 18.326a8.663 8.663 0 11-12.994-7.492V5.332a4.332 4.332 0 118.663 0v5.5a8.654 8.654 0 014.331 7.494zM9.333 10.834l.14-.086h-.14v.086zm4.331-7.666c-1.196 0-2.166.97-2.166 2.166v4.613a8.377 8.377 0 014.332 0V5.332c0-1.197-.97-2.166-2.166-2.166v.002zm4.332 7.58h-.141l.14.086v-.088.002zm-4.332 1.069a6.51 6.51 0 10-.004 13.025 6.51 6.51 0 00.004-13.027v.002zm0 8.677a1.083 1.083 0 01-1.083-1.083v-2.166a1.083 1.083 0 012.166 0v2.166a1.083 1.083 0 01-1.083 1.08v.003z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});