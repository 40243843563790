define("ember-svg-jar/inlined/youtube-short", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M20.398 10.948s.602.252 1.68.7a5.266 5.266 0 013.122 4.55 5.23 5.23 0 01-2.786 4.886l-11.9 6.3A5.252 5.252 0 015.6 18.102l1.988-1.05s-1.638-.686-1.68-.7a5.27 5.27 0 01-3.108-4.55 5.242 5.242 0 012.8-4.886l11.886-6.3A5.252 5.252 0 0124.584 2.8c1.358 2.562.392 5.74-2.184 7.084l-2.002 1.064zM11.2 17.71v-7.42l7 3.71-7 3.71z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});