define("ember-svg-jar/inlined/arrow-sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.986 18.7l6.097 6.097L20.18 18.7a1.327 1.327 0 111.876 1.876l-7.035 7.035a1.327 1.327 0 01-1.876 0L6.11 20.576A1.327 1.327 0 017.986 18.7zM14.8.389l7.035 7.035A1.327 1.327 0 0119.96 9.3l-6.097-6.097L7.765 9.3a1.327 1.327 0 11-1.876-1.876L12.924.389a1.327 1.327 0 011.876 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});