define("styleguide/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    // Product Route
    this.route('product', function () {
      this.route('get-started', function () {
        this.route('guideline-product-designers');
        this.route('guideline-developers');
        this.route('guideline-marketing');
      });
      this.route('foundation', function () {
        this.route('brand-colors');
        this.route('variables');
        this.route('layout');
        this.route('utility-helpers');
        this.route('copy-translations');
      });
      this.route('components', function () {
        this.route('post-modal');
        this.route('linkinbio');
        this.route('partnership');
      });
      this.route('objects', function () {
        this.route('alert');
        this.route('badge');
        this.route('button');
        this.route('button-bar');
        this.route('button-group');
        this.route('card');
        this.route('empty-state');
        this.route('image');
        this.route('input-form');
        this.route('link');
        this.route('media');
        this.route('meter');
        this.route('modal');
        this.route('popover');
        this.route('switchers');
        this.route('tab');
        this.route('table');
        this.route('tooltip');
        this.route('user');
        this.route('video');
      });
      this.route('responsive', function () {
        this.route('home');
      });
    }); // UX Route

    this.route('ux', function () {
      this.route('get-started', function () {
        this.route('guideline-product-designers');
        this.route('guideline-developers');
        this.route('guideline-marketing');
      });
      this.route('established-psychology', function () {
        this.route('topics');
      });
      this.route('use-cases', function () {
        this.route('layout');
        this.route('users-action');
        this.route('calendar');
        this.route('media-library');
        this.route('conversations');
        this.route('analytics');
        this.route('collect');
        this.route('linkinbio-management');
      });
      this.route('copy', function () {
        this.route('voice');
        this.route('tone');
        this.route('principles');
        this.route('naming');
        this.route('components');
        this.route('punctuation');
        this.route('grammar-and-style');
        this.route('lexicon');
      });
    }); // Brand Route

    this.route('brand'); // Marketing Route

    this.route('marketing');
  });

  _exports.default = _default;
});