define("@mainmatter/ember-api-actions/index", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.adapterAction = adapterAction;
  _exports.apiAction = apiAction;
  const VALID_METHODS = ['GET', 'HEAD', 'POST', 'PUT', 'DELETE', 'OPTIONS', 'PATCH'];

  async function apiAction(record, _ref) {
    let {
      requestType = 'updateRecord',
      method,
      path,
      data,
      adapterOptions
    } = _ref;
    (false && !(method) && (0, _debug.assert)(`Missing \`method\` option`, method));
    (false && !(VALID_METHODS.includes(method)) && (0, _debug.assert)([`Invalid \`method\` option: ${method}`, `Valid options: ${VALID_METHODS.join(', ')}`].join('\n'), VALID_METHODS.includes(method)));
    let modelClass = record.constructor;
    let modelName = modelClass.modelName;
    let adapter = record.store.adapterFor(modelName);

    let snapshot = record._createSnapshot();

    if (adapterOptions) {
      snapshot.adapterOptions = adapterOptions;
    }

    let baseUrl = adapter.buildURL(modelName, record.id, snapshot, requestType);
    let url = addPath(baseUrl, path);
    return await adapter.ajax(url, method, {
      data
    });
  }
  /** @experimental */


  async function adapterAction(adapter, modelName, _ref2) {
    let {
      requestType = 'createRecord',
      method,
      path,
      data,
      adapterOptions
    } = _ref2;
    (false && !(method) && (0, _debug.assert)(`Missing \`method\` option`, method));
    (false && !(VALID_METHODS.includes(method)) && (0, _debug.assert)([`Invalid \`method\` option: ${method}`, `Valid options: ${VALID_METHODS.join(', ')}`].join('\n'), VALID_METHODS.includes(method)));
    let baseUrl = adapter.buildURL(modelName, null, {
      adapterOptions
    }, requestType);
    let url = addPath(baseUrl, path);
    return await adapter.ajax(url, method, {
      data
    });
  }

  function addPath(baseUrl, path) {
    if (!path) return baseUrl;
    let url = new URL(baseUrl, location.href);
    let [pathname, search] = path.split('?', 2);
    url.pathname += `/${pathname}`;

    if (search) {
      new URLSearchParams(search).forEach((value, name) => {
        url.searchParams.append(name, value);
      });
    }

    return url.href;
  }
});