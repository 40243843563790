define("@latermedia/ember-later-analytics/utils/state-types/no-boards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Utils
   */

  /**
   * @class NoBoardsUtil
   * @extends Utils
   */

  /**
   * No Boards State
   *
   * @property NO_BOARDS
   * @returns {string}
   */
  const NO_BOARDS = 'noBoards';
  var _default = NO_BOARDS;
  _exports.default = _default;
});