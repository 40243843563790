define("ember-svg-jar/inlined/ellipsis-h", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M4.182 11C2.095 11 .5 12.595.5 14.682c0 2.086 1.595 3.682 3.682 3.682 2.086 0 3.682-1.596 3.682-3.682C7.864 12.595 6.268 11 4.182 11zm0 4.91c-.737 0-1.227-.492-1.227-1.228 0-.737.49-1.227 1.227-1.227.736 0 1.227.49 1.227 1.227 0 .736-.49 1.227-1.227 1.227zM14 11c-2.086 0-3.682 1.595-3.682 3.682 0 2.086 1.596 3.682 3.682 3.682s3.682-1.596 3.682-3.682C17.682 12.595 16.086 11 14 11zm0 4.91c-.736 0-1.227-.492-1.227-1.228 0-.737.49-1.227 1.227-1.227.736 0 1.227.49 1.227 1.227 0 .736-.49 1.227-1.227 1.227zM23.818 11c-2.086 0-3.682 1.595-3.682 3.682 0 2.086 1.596 3.682 3.682 3.682 2.087 0 3.682-1.596 3.682-3.682 0-2.087-1.595-3.682-3.682-3.682zm0 4.91c-.736 0-1.227-.492-1.227-1.228 0-.737.49-1.227 1.227-1.227.737 0 1.227.49 1.227 1.227 0 .736-.49 1.227-1.227 1.227z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});