define("ember-svg-jar/inlined/web", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path fill-rule=\"nonzero\" d=\"M-29.67 17.132a4.229 4.229 0 017.97-2.625l4.226-.82 2.373-12.184a1.048 1.048 0 112.059.4l-1.9 9.795.602-.619a1 1 0 111.433 1.394l-.393.404 9.408-1.824c.528-.103 1.04.21 1.199.71l.03.12c.11.567-.26 1.118-.83 1.228l-12.185 2.374-.818 4.226a4.229 4.229 0 11-2.073-.402l.66-3.389-3.389.66a4.229 4.229 0 01-3.717 3.944l-.226.02a4.229 4.229 0 01-4.43-3.412zm9.64 7.481a2.088 2.088 0 103.463-2.335 2.088 2.088 0 00-3.462 2.335zm9.176-17.118a1 1 0 111.433 1.394l-1.743 1.792a1 1 0 11-1.433-1.394l1.743-1.792zm3.486-3.585a1 1 0 011.434 1.395l-1.743 1.792a1 1 0 11-1.434-1.394l1.743-1.793zM-3.882.326a1 1 0 011.434 1.395l-1.743 1.792a1 1 0 01-1.434-1.395L-3.882.326zm-20.385 14.251a2.088 2.088 0 10-2.336 3.463 2.088 2.088 0 002.336-3.463z\"/><path d=\"M24.182 1.5c2.096 0 3.714 1.553 3.813 3.617l.005.201v12.727c0 2.096-1.553 3.715-3.617 3.814l-.201.005h-8.91v2.545h3.819c.764 0 1.273.51 1.273 1.273 0 .716-.448 1.208-1.133 1.267l-.14.006H8.909c-.764 0-1.273-.51-1.273-1.273 0-.716.448-1.208 1.133-1.267l.14-.006h3.818v-2.545H3.818c-2.096 0-3.714-1.553-3.813-3.617L0 18.045V5.318c0-2.096 1.553-3.714 3.617-3.813l.201-.005h20.364zm0 2.545H3.818c-.716 0-1.208.448-1.267 1.133l-.006.14v12.727c0 .716.448 1.209 1.133 1.267l.14.006h20.364c.716 0 1.208-.447 1.267-1.132l.006-.14V5.317c0-.763-.51-1.273-1.273-1.273zM14.636 9.41l.07.012 5.674 1.555c.303.092.35.464.116.626l-1.377.952 2.685 2.715c.14.14.14.348 0 .487l-1.19 1.184a.34.34 0 01-.491 0l-2.709-2.692-.957 1.37c-.164.254-.537.185-.607-.14l-1.565-5.64a.34.34 0 01.42-.417zM5.273 6.3a1.273 1.273 0 110 2.545 1.273 1.273 0 010-2.545zm3.182 0a1.273 1.273 0 110 2.545 1.273 1.273 0 010-2.545zm3.181 0a1.273 1.273 0 110 2.545 1.273 1.273 0 010-2.545z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});