define("ember-svg-jar/inlined/tone-casual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.783 2.292A10.7 10.7 0 017.379.714h.982v.701c0 1.33 1.045 2.365 2.389 2.365 1.344 0 2.39-1.035 2.39-2.365v-.7h.981a10.7 10.7 0 015.596 1.577l1.033.634V8.86h-3.097v10.425H3.847V8.86H.75V2.926l1.033-.634zm5.227-.169a9.27 9.27 0 00-4.481 1.36l-.363.223V7.46h3.097v10.424h10.974V7.46h3.097V3.706l-.363-.223a9.27 9.27 0 00-4.48-1.36c-.329 1.757-1.86 3.059-3.741 3.059-1.88 0-3.412-1.302-3.74-3.059z\"/><path d=\"M9.321 8.214a1.071 1.071 0 11-2.142 0 1.071 1.071 0 012.142 0zM14.321 8.214a1.071 1.071 0 11-2.142 0 1.071 1.071 0 012.142 0zM8.101 11.613c.277-.277.788-.226 1.068.045.059.045.156.11.292.178.27.135.697.282 1.288.282.59 0 1.018-.147 1.288-.282.136-.068.233-.133.292-.178.28-.271.791-.322 1.068-.045a.714.714 0 010 1.01l-.068.063c-.012.011.017-.015 0 0a3.355 3.355 0 01-.653.428 4.28 4.28 0 01-1.927.433 4.28 4.28 0 01-1.927-.433 3.358 3.358 0 01-.653-.428c-.017-.015.142.115 0 0l-.066-.06a.717.717 0 01-.002-1.013z\"/>",
    "attrs": {
      "width": "21",
      "height": "20",
      "viewBox": "0 0 21 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});