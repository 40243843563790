define("@latermedia/ember-later-analytics/helpers/rounded-value", ["exports", "@ember/component/helper", "@latermedia/ember-later-analytics/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.roundedValue = roundedValue;

  /**
   * Rounds the number up to the closest whole number.
  
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function roundedValue(params) {
    if (params[0] === null || isNaN(params[0])) {
      return "-";
    }

    return (0, _utils.preciseRound)(params[0], 0);
  }

  var _default = (0, _helper.helper)(roundedValue);

  _exports.default = _default;
});