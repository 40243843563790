define("@latermedia/ember-later-analytics/components/ui/bar-chart", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class="cCH--chartList cCH--chartList--vertical" data-test-id="bar-chart">
    {{#each @bars as |bar|}}
      <li class="cCH--chartList__item">
        <div class="cCH--barChart">
          <div 
            class={{concat "cCH--barChart__unit cCH--barChart__unit--" bar.rank}} 
            style={{t "formatters.style_height" percentage_of_height=bar.percentageOfHeight}}
          >
            {{#if bar.isCountVisible}}
              <span class="cCH--barChart__value">{{bar.count}}</span>
            {{/if}}
          </div>
        </div>
        <span class="cCH--barChart__time">
          {{bar.visibleTitle}}
        </span>
      </li>
    {{/each}}
  </ul>
  
  */
  {
    "id": "y0/cKAbh",
    "block": "[[[10,\"ul\"],[14,0,\"cCH--chartList cCH--chartList--vertical\"],[14,\"data-test-id\",\"bar-chart\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[14,0,\"cCH--chartList__item\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"cCH--barChart\"],[12],[1,\"\\n        \"],[10,0],[15,0,[28,[37,2],[\"cCH--barChart__unit cCH--barChart__unit--\",[30,2,[\"rank\"]]],null]],[15,5,[28,[37,3],[\"formatters.style_height\"],[[\"percentage_of_height\"],[[30,2,[\"percentageOfHeight\"]]]]]],[12],[1,\"\\n\"],[41,[30,2,[\"isCountVisible\"]],[[[1,\"            \"],[10,1],[14,0,\"cCH--barChart__value\"],[12],[1,[30,2,[\"count\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"cCH--barChart__time\"],[12],[1,\"\\n        \"],[1,[30,2,[\"visibleTitle\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"@bars\",\"bar\"],false,[\"each\",\"-track-array\",\"concat\",\"t\",\"if\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/bar-chart.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});