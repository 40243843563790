define("ember-svg-jar/inlined/review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M17 0c1.586 0 2.937.935 3.495 2.357l.069.188h1.527c2.096 0 3.714 1.553 3.813 3.617l.005.202v17.818c0 2.096-1.553 3.714-3.617 3.813l-.201.005H6.818c-2.096 0-3.714-1.553-3.813-3.617L3 24.182V6.364C3 4.268 4.553 2.649 6.617 2.55l.201-.005h1.527a3.7 3.7 0 013.359-2.54L11.909 0H17zM8.345 5.09H6.818c-.716 0-1.208.448-1.267 1.134l-.006.14v17.818c0 .716.448 1.208 1.133 1.267l.14.006h15.273c.716 0 1.208-.448 1.267-1.133l.006-.14V6.364c0-.716-.448-1.208-1.133-1.267l-.14-.006h-1.527a3.7 3.7 0 01-3.359 2.54L17 7.636h-5.09c-1.587 0-2.938-.935-3.496-2.357l-.069-.188zm9.908 8.178c.44-.44 1.099-.44 1.648-.11.408.408.437 1.006.088 1.441l-.088.098-5.494 5.494a1.058 1.058 0 01-1.441.088l-.098-.088-3.077-3.077a1.062 1.062 0 010-1.538 1.058 1.058 0 011.441-.088l.098.088 2.307 2.307 4.616-4.615zM17 2.545h-5.09c-.765 0-1.274.51-1.274 1.273 0 .716.448 1.208 1.133 1.267l.14.006H17c.764 0 1.273-.51 1.273-1.273 0-.716-.448-1.208-1.133-1.267L17 2.545z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});