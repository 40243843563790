define("ember-svg-jar/inlined/image-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14 0c.324 0 .659.014.988.042a.732.732 0 01.193.011c4.964.417 9.308 3.457 11.444 7.9.022.041.04.08.043.094A13.918 13.918 0 0128 14c0 2.844-.855 5.565-2.4 7.82a.689.689 0 01-.137.203A13.987 13.987 0 0114 28c-.327 0-.664-.015-1.008-.042-.098 0-.098 0-.136-.006a14.025 14.025 0 01-11.523-7.999A13.911 13.911 0 010 14c0-2.844.855-5.565 2.4-7.82a.687.687 0 01.137-.203A13.988 13.988 0 0114 0zm2.705 9.314h-5.41L8.589 14l2.706 4.686h5.41L19.411 14l-2.706-4.686zm-1.159-7.365l-3.184 5.516h11.874a12.165 12.165 0 00-8.69-5.516zM4.34 6.64l3.18 5.51 2.44-4.224 3.502-6.066A12.137 12.137 0 004.34 6.64zM1.85 14c0 1.627.323 3.211.943 4.686h6.366L6.72 14.462l-3.5-6.061A12.064 12.064 0 001.85 14zm13.788 6.536H3.764a12.166 12.166 0 008.69 5.515l3.184-5.515zm8.022.823l-3.181-5.51-1.972 3.408a.146.146 0 01-.034.066l-3.935 6.816a12.137 12.137 0 009.122-4.78zM26.15 14c0-1.627-.323-3.211-.943-4.686h-6.366l2 3.463 3.938 6.822A12.065 12.065 0 0026.151 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});