define("@latermedia/ember-later-analytics/utils/state-types/partial-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Utils
   */

  /**
   * @class PartialDataUtil
   * @extends Utils
   */

  /**
   * Partial Data State
   *
   * @property PARTIAL_DATA
   * @returns {string}
   */
  const PARTIAL_DATA = 'partialData';
  var _default = PARTIAL_DATA;
  _exports.default = _default;
});