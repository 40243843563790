define("ember-svg-jar/inlined/add-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14 .5C6.514.5.5 6.514.5 14S6.514 27.5 14 27.5 27.5 21.486 27.5 14 21.486.5 14 .5zm0 24.545A11 11 0 012.955 14 11 11 0 0114 2.955 11 11 0 0125.045 14 11 11 0 0114 25.045zm4.91-12.272h-3.683V9.09c0-.736-.49-1.227-1.227-1.227-.736 0-1.227.49-1.227 1.227v3.682H9.09c-.736 0-1.227.49-1.227 1.227 0 .736.49 1.227 1.227 1.227h3.682v3.682c0 .736.49 1.227 1.227 1.227.736 0 1.227-.49 1.227-1.227v-3.682h3.682c.736 0 1.227-.49 1.227-1.227 0-.736-.49-1.227-1.227-1.227z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});