define("ember-svg-jar/inlined/play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M22.417 15l-12.64 7.639V7.36L22.418 15zM7 24.51c0 1.651 1.15 2.3 2.575 1.445l15.683-9.41c1.422-.853 1.426-2.235 0-3.09L9.575 4.045C8.153 3.192 7 3.845 7 5.491v19.018z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "29",
      "height": "29",
      "viewBox": "0 0 29 29"
    }
  };
  _exports.default = _default;
});