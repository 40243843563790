define("ember-svg-jar/inlined/grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M25.667 28h-7a2.333 2.333 0 01-2.334-2.333v-7a2.333 2.333 0 012.334-2.334h7A2.333 2.333 0 0128 18.667v7A2.333 2.333 0 0125.667 28zm0-9.333h-7v7h7v-7zm0-7h-7a2.333 2.333 0 01-2.334-2.334v-7A2.333 2.333 0 0118.667 0h7A2.333 2.333 0 0128 2.333v7a2.333 2.333 0 01-2.333 2.334zm0-9.334h-7v7h7v-7zM9.333 28h-7A2.333 2.333 0 010 25.667v-7a2.333 2.333 0 012.333-2.334h7a2.333 2.333 0 012.334 2.334v7A2.333 2.333 0 019.333 28zm0-9.333h-7v7h7v-7zm0-7h-7A2.333 2.333 0 010 9.333v-7A2.333 2.333 0 012.333 0h7a2.333 2.333 0 012.334 2.333v7a2.333 2.333 0 01-2.334 2.334zm0-9.334h-7v7h7v-7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});