define("ember-svg-jar/inlined/sparkle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.19 2.814L5.526.202c-.069-.27-.457-.27-.525 0l-.663 2.612c-.097.382-.4.68-.788.776l-2.65.653a.266.266 0 000 .518l2.65.653c.388.095.691.394.788.776l.663 2.612c.068.27.456.27.525 0l.662-2.612c.097-.382.4-.68.788-.776l2.65-.653a.266.266 0 000-.518l-2.65-.653a1.075 1.075 0 01-.788-.776zM10.326 9.397l-.285-1.123c-.058-.227-.385-.227-.443 0l-.285 1.123a.907.907 0 01-.665.655l-1.14.281a.224.224 0 000 .437l1.14.28c.328.082.583.333.665.656l.285 1.123c.058.228.385.228.443 0l.285-1.123a.907.907 0 01.665-.655l1.14-.28a.224.224 0 000-.438l-1.14-.28a.907.907 0 01-.665-.656z\"/>",
    "attrs": {
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});