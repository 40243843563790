define("ember-svg-jar/inlined/android", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.305 5.972c.176 0 .294-.059.47-.177a.669.669 0 00.178-.471.669.669 0 00-.177-.471c-.177-.118-.295-.177-.471-.177a.669.669 0 00-.471.177.669.669 0 00-.177.471c0 .177.059.353.177.471a.669.669 0 00.47.177zm7.068 0a.669.669 0 00.47-.177.669.669 0 00.177-.471.669.669 0 00-.176-.471.669.669 0 00-.471-.177c-.177 0-.295.059-.472.177a.669.669 0 00-.176.471c0 .177.059.353.176.471.177.118.295.177.472.177zM3.708 9.094c.471 0 .884.117 1.237.47.353.354.471.766.471 1.238v7.244c0 .471-.118.884-.471 1.178-.353.353-.766.53-1.237.53-.471 0-.883-.177-1.237-.53A1.645 1.645 0 012 18.046v-7.244c0-.472.177-.884.471-1.237.354-.354.766-.471 1.237-.471zm17.846.294v11.19c0 .53-.176.943-.53 1.355-.353.354-.765.53-1.295.53h-1.237v3.829c0 .471-.177.883-.53 1.178-.354.353-.766.53-1.237.53-.472 0-.884-.177-1.237-.53a1.645 1.645 0 01-.471-1.178v-3.828H12.66v3.828c0 .471-.177.883-.472 1.178-.353.353-.765.53-1.236.53-.472 0-.884-.177-1.237-.53a1.645 1.645 0 01-.471-1.178l-.06-3.828H7.95c-.471 0-.943-.177-1.296-.53-.353-.413-.53-.825-.53-1.355V9.388h15.431zm-3.887-6.832c1.178.648 2.18 1.472 2.886 2.591a6.614 6.614 0 011.06 3.652H6.064c0-1.355.353-2.533 1.06-3.652.707-1.119 1.708-1.943 2.886-2.591L8.832.376C8.773.2 8.773.082 8.892.023c.176-.059.294 0 .353.118l1.236 2.238a8.175 8.175 0 013.358-.707c1.178 0 2.297.236 3.357.707L18.433.141c.059-.118.176-.177.353-.118.118.059.118.177.059.353l-1.178 2.18zm8.01 8.246v7.244c0 .471-.176.884-.47 1.178-.354.353-.767.53-1.238.53s-.883-.177-1.237-.53c-.353-.294-.47-.707-.47-1.178v-7.244c0-.472.117-.884.47-1.237.354-.354.766-.471 1.237-.471.471 0 .884.117 1.237.47.295.354.471.766.471 1.238z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});