define("ember-svg-jar/inlined/book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M3.13 11.65v11.988c1.073-.648 2.39-1.208 3.84-1.439 1.89-.3 3.975-.032 5.965 1.275V11.608C11.04 9.78 9.054 9.436 7.305 9.715c-1.695.27-3.208 1.134-4.175 1.936zm11.935 0v11.823c1.074-.648 2.39-1.043 3.84-1.274 1.89-.3 3.975-.031 5.965 1.275V11.608c-1.895-1.829-3.88-2.172-5.63-1.893-1.695.27-3.208 1.134-4.175 1.936zm-1.016-1.91c-2.311-2.038-4.833-2.486-7.08-2.128-2.417.384-4.468 1.688-5.633 2.782A1.065 1.065 0 001 11.17v14.588a1.065 1.065 0 001.794.776c.929-.872 2.604-1.928 4.51-2.232 1.84-.293 3.941.102 5.924 2.189a1.065 1.065 0 001.5.043c.93-.872 2.606-1.928 4.511-2.232 1.84-.293 3.941.102 5.924 2.19A1.065 1.065 0 0027 25.757V11.17c0-.273-.105-.535-.293-.733-2.5-2.632-5.32-3.22-7.803-2.825-1.946.31-3.655 1.215-4.855 2.127zM19.1 6.212a1.083 1.083 0 010-1.532l1.292-1.293a1.083 1.083 0 011.532 1.532L20.63 6.212a1.083 1.083 0 01-1.532 0zm-13-2.825a1.083 1.083 0 011.531 0L8.924 4.68a1.083 1.083 0 11-1.532 1.532L6.099 4.919a1.083 1.083 0 010-1.532zM14 5.333a1.083 1.083 0 01-1.083-1.083V2.083a1.083 1.083 0 112.166 0V4.25c0 .598-.485 1.083-1.083 1.083z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});