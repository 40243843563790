define("@latermedia/ember-later-analytics/models/report", ["exports", "@latermedia/ember-later-analytics/models/data"], function (_exports, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ReportModel {
    constructor(data, meta) {
      let config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
        generateDefaultHighlights: true
      };

      _defineProperty(this, "data", void 0);

      this.data = new _data.default(data, meta, config);
    }

  }

  _exports.default = ReportModel;
});