define("@latermedia/ember-later-analytics/components/ui/bar-chart-loading", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div data-test-id="bar-chart-loading">
    <div class="cCH--loading cCH--loading--tall"></div>
    <div class="cCH--loading cCH--loading--tall"></div>
    <div class="cCH--loading cCH--loading--tall"></div>
    <div class="cCH--loading cCH--loading--tall"></div>
    <div class="cCH--loading cCH--loading--tall"></div>
    <div class="cCH--loading cCH--loading--tall"></div>
    <div class="cCH--loading cCH--loading--tall"></div>
    <div class="cCH--loading cCH--loading--tall"></div>
  </div>
  */
  {
    "id": "1RCBbKkE",
    "block": "[[[10,0],[14,\"data-test-id\",\"bar-chart-loading\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"cCH--loading cCH--loading--tall\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"cCH--loading cCH--loading--tall\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"cCH--loading cCH--loading--tall\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"cCH--loading cCH--loading--tall\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"cCH--loading cCH--loading--tall\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"cCH--loading cCH--loading--tall\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"cCH--loading cCH--loading--tall\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"cCH--loading cCH--loading--tall\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/bar-chart-loading.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});