define("ember-svg-jar/inlined/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M54.649-26.565a1.54 1.54 0 01-.004 2.188l-8.419 8.418 8.419 8.42c.571.571.608 1.473.1 2.083l-.096.104a1.54 1.54 0 01-2.188-.004l-8.419-8.419-8.419 8.42a1.544 1.544 0 01-2.083.099l-.105-.096a1.54 1.54 0 01.004-2.188l8.419-8.42-8.419-8.417a1.544 1.544 0 01-.1-2.084l.096-.104a1.54 1.54 0 012.188.004l8.419 8.418 8.419-8.418a1.544 1.544 0 012.083-.1l.105.096zM24.667 22.667V27H3v-4.333c0-5.464 1.722-4.647 8.192-7.44C8.92 13.594 7.333 10.15 7.333 7.5a6.5 6.5 0 1113 0c0 2.649-1.591 6.094-3.863 7.727 6.47 2.793 8.197 1.976 8.197 7.44z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});