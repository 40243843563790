define("ember-svg-jar/inlined/exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M23.24 9.02l-3.2 3.44c-.56.56-.56 1.4 0 1.96s1.4.56 1.96 0l5.58-5.82c.56-.56.56-1.4 0-1.96L22 .42c-.28-.28-.7-.42-.98-.42-.42 0-.7.14-.98.42-.56.56-.56 1.4 0 1.96l3.2 3.84H1.4c-.84 0-1.4.56-1.4 1.4 0 .84.7 1.4 1.4 1.4h21.84zM4.76 18.82l3.2-3.44c.56-.56.56-1.4 0-1.96s-1.4-.56-1.96 0L.42 19.24c-.56.56-.56 1.4 0 1.96L6 27.42c.28.28.7.42.98.42.42 0 .7-.14.98-.42.56-.56.56-1.4 0-1.96l-3.2-3.84H26.6c.84 0 1.4-.56 1.4-1.4 0-.84-.7-1.4-1.4-1.4H4.76z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});