define("ember-svg-jar/inlined/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M18.5 23.3c-1.5.767-3.2 1.2-5 1.2-1.8 0-3.5-.433-5-1.2v-1.05a2.5 2.5 0 012.5-2.5h5a2.5 2.5 0 012.5 2.5v1.05zm2.456-1.713A5.001 5.001 0 0016 17.25h-5a5.001 5.001 0 00-4.956 4.337A10.97 10.97 0 012.5 13.5c0-6.075 4.925-11 11-11s11 4.925 11 11a10.97 10.97 0 01-3.544 8.087zM27 13.5C27 20.956 20.956 27 13.5 27S0 20.956 0 13.5 6.044 0 13.5 0 27 6.044 27 13.5zm-13.5-5a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zm-5.25 2.75a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});