define("ember-svg-jar/inlined/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#a)\"><path d=\"M25.5 14c0 6.351-5.149 11.5-11.5 11.5S2.5 20.351 2.5 14H0c0 7.732 6.268 14 14 14s14-6.268 14-14S21.732 0 14 0A13.97 13.97 0 003.25 5.03V2a1.25 1.25 0 10-2.5 0v7.25H8a1.25 1.25 0 100-2.5H5.073A11.478 11.478 0 0114 2.5c6.351 0 11.5 5.149 11.5 11.5z\"/><path d=\"M18.71 16.927a1.23 1.23 0 010 1.782 1.375 1.375 0 01-.892.382 1.37 1.37 0 01-.89-.382l-3.819-3.818c-.254-.255-.382-.51-.382-.891V6.364c0-.764.51-1.273 1.273-1.273.764 0 1.273.509 1.273 1.273v7.127l3.436 3.436z\"/></g><defs><clipPath id=\"a\"><path d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});