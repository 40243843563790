define("ember-svg-jar/inlined/linkin-bio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#a)\"><path fill-rule=\"evenodd\" d=\"M19.818 16.12c1.15-1.15 2.432-3.382 2.432-4.743a5.59 5.59 0 00-5.59-5.591c-1.35 0-3.594 1.282-4.744 2.432l-1.694-1.693C11.74 4.987 14.515 3.39 16.66 3.39a7.923 7.923 0 014.732 1.573l2.795-2.795 1.693 1.693-2.795 2.796a7.923 7.923 0 011.561 4.72c0 2.132-1.597 4.907-3.135 6.437l-1.693-1.694zm-17.97 8.375L4.645 21.7a7.923 7.923 0 01-1.561-4.732c0-2.133 1.597-4.908 3.134-6.438l1.693 1.694c-1.15 1.15-2.432 3.382-2.432 4.744a5.59 5.59 0 005.591 5.59c1.35 0 3.594-1.282 4.744-2.432l1.693 1.693c-1.517 1.538-4.293 3.135-6.437 3.135a7.923 7.923 0 01-4.732-1.561l-2.795 2.795-1.693-1.693zm25.801-7.202a1.198 1.198 0 11-1.694-1.695 1.198 1.198 0 011.694 1.695zM10.707 2.045A1.198 1.198 0 1012.401.351a1.198 1.198 0 00-1.694 1.694zM23.88 23.886a1.997 1.997 0 11-2.824-2.824 1.997 1.997 0 012.824 2.824zM6.938 6.945A1.997 1.997 0 114.114 4.12a1.997 1.997 0 012.824 2.824zm6.28 10.975a3.993 3.993 0 111.558-7.832 3.993 3.993 0 01-1.558 7.832zm2.345-4.228a1.597 1.597 0 10-3.132.623 1.597 1.597 0 003.133-.623zm-13.518-1.29a1.198 1.198 0 11-1.694-1.694A1.198 1.198 0 012.045 12.4zM15.6 27.648a1.198 1.198 0 101.694-1.694 1.198 1.198 0 00-1.694 1.694z\" clip-rule=\"evenodd\"/></g><defs><clipPath id=\"a\"><path d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});