define("ember-svg-jar/inlined/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14 3.508c9.333 0 14 10.5 14 10.5s-4.667 10.5-14 10.5-14-10.5-14-10.5l.012-.027C.282 13.39 4.945 3.508 14 3.508zm0 2.333c-6.183 0-10.033 5.834-11.317 8.167 1.284 2.333 5.25 8.167 11.317 8.167 6.183 0 10.033-5.834 11.317-8.167C24.033 11.675 20.183 5.841 14 5.841zm0 2.334c3.208 0 5.833 2.625 5.833 5.833S17.208 19.841 14 19.841s-5.833-2.625-5.833-5.833S10.792 8.175 14 8.175zm0 2.333a3.51 3.51 0 00-3.5 3.5 3.51 3.51 0 003.5 3.5 3.51 3.51 0 003.5-3.5 3.51 3.51 0 00-3.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});