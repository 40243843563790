define("ember-svg-jar/inlined/bold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M20.21 13.51a6.07 6.07 0 001.67-4.18 6.13 6.13 0 00-6.13-6.12H6.42A1.46 1.46 0 005 4.67v18.66c0 .806.654 1.46 1.46 1.46h10.5a6.12 6.12 0 003.29-11.28h-.04zM7.88 6.13h7.87a3.21 3.21 0 110 6.41H7.88V6.13zm9 15.75h-9v-6.42h9a3.21 3.21 0 010 6.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});