define("ember-svg-jar/inlined/notes-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M13.845 5.384a.985.985 0 00.012.013l.586.585L11.825 8.6H8.152a.967.967 0 00-.685.29L4.89 11.469a.952.952 0 000 1.37l4.434 4.434-8.283 8.317a.952.952 0 000 1.37.967.967 0 00.685.291.967.967 0 00.685-.29l8.283-8.282 4.434 4.433a.967.967 0 00.685.291.967.967 0 00.685-.29l2.578-2.578a.967.967 0 00.29-.685v-3.672l2.62-2.617.585.585a.967.967 0 00.685.291.967.967 0 00.685-.291l2.578-2.577c.962-.923.975-2.46.036-3.44l-6.68-6.679c-.933-.932-2.468-.932-3.436-.003l-2.582 2.58a.967.967 0 00-.29.686c0 .247.097.485.278.672zm3.987-2.503c.21-.191.494-.152.628-.017l6.66 6.659c.194.211.147.474.015.6l-.015.015-1.51 1.51a.5.5 0 01-.708 0l-.563-.563a.5.5 0 00-.707 0l-4.119 4.117a.5.5 0 00-.146.354v3.651a.5.5 0 01-.147.354l-1.053 1.053a.5.5 0 01-.708 0l-3.327-3.327.018-.018-4.763-4.762a.5.5 0 010-.707l1.053-1.054a.5.5 0 01.354-.146h3.652a.5.5 0 00.354-.146l4.118-4.118a.5.5 0 000-.707l-.563-.564a.5.5 0 010-.707l1.477-1.477z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});