define("@latermedia/ember-later-analytics/utils/time-methods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unixToWeekday = _exports.unixToDate = _exports.default = _exports.daysBetweenTimes = _exports.dateStringToUnix = _exports.convertTimezoneLongToShort = void 0;

  /**
   * Convert long timezone to short timezone.
   *
   * @param longTimezone The timezone to shorten
   * @returns Short timezone
   */
  const convertTimezoneLongToShort = longTimezone => {
    if (!longTimezone) {
      return null;
    }

    const effectiveTimezone = longTimezone ? {
      timeZoneName: 'short',
      timeZone: longTimezone
    } : {};
    return new Date().toLocaleTimeString('en-us', effectiveTimezone).split(' ')[2];
  };
  /**
   * Date string to unix timestamp.
   *
   * @param dateStr Date string to convert
   * @returns Unix timestamp
   */


  _exports.convertTimezoneLongToShort = convertTimezoneLongToShort;

  const dateStringToUnix = dateStr => {
    if (!dateStr) {
      return null;
    }

    return Date.parse(dateStr) / 1000;
  };
  /**
   * Unix timestamp to date string.
   *
   * @param unixTime Unix timestamp
   * @param timezone Timezone
   * @returns Date string
   */


  _exports.dateStringToUnix = dateStringToUnix;

  const unixToDate = (unixTime, timezone) => {
    if (!unixTime) {
      return null;
    }

    const effectiveTimezone = timezone ? {
      timezone
    } : {};
    return new Date(unixTime * 1000).toLocaleDateString('en-US', { ...effectiveTimezone,
      month: 'short',
      year: 'numeric',
      day: 'numeric'
    });
  };
  /**
   * Unix timestamp to weekday string.
   *
   * @param unixTime Unix timestamp
   * @param timezone Timezone
   * @param type Weekday type (ex. long, short, narrow)
   * @returns
   */


  _exports.unixToDate = unixToDate;

  const unixToWeekday = function (unixTime, timezone) {
    let type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'long';

    if (!unixTime) {
      return null;
    }

    const effectiveTimezone = timezone ? {
      timezone
    } : {};
    return new Date(unixTime * 1000).toLocaleDateString('en-US', { ...effectiveTimezone,
      weekday: type
    });
  };
  /**
   * Get the number of days between two unix timestamps.
   *
   * @param startTimeUnix Starting unix timestamp
   * @param endTimeUnix End unix timestamp
   * @returns Days between timestamps
   */


  _exports.unixToWeekday = unixToWeekday;

  const daysBetweenTimes = (startTimeUnix, endTimeUnix) => {
    if (!startTimeUnix || !endTimeUnix) {
      return null;
    }

    const oneDayInS = 24 * 60 * 60;
    return Math.round(Math.abs(startTimeUnix - endTimeUnix) / oneDayInS);
  };

  _exports.daysBetweenTimes = daysBetweenTimes;
  var _default = {
    convertTimezoneLongToShort,
    dateStringToUnix,
    unixToDate,
    unixToWeekday,
    daysBetweenTimes
  };
  _exports.default = _default;
});