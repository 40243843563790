define("ember-svg-jar/inlined/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.857 5.397a.967.967 0 01-.29-.685c0-.253.102-.497.29-.685l2.582-2.581c.968-.929 2.504-.929 3.436.003l6.68 6.68c.939.98.926 2.516-.036 3.439l-2.578 2.577a.967.967 0 01-.685.291.967.967 0 01-.685-.291l-.586-.586-2.618 2.618v3.672a.967.967 0 01-.291.685l-2.578 2.577a.967.967 0 01-.685.291.967.967 0 01-.685-.29l-4.434-4.434-8.283 8.281a.967.967 0 01-.685.291.967.967 0 01-.685-.291.951.951 0 010-1.37l8.283-8.317-4.434-4.434a.952.952 0 010-1.37l2.577-2.577a.967.967 0 01.685-.291h3.673l2.618-2.618-.586-.585zM11.928 8.6l-.177.073.074-.073h.103zm5.56 10.782l-.073.176v-.103l.073-.073z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});