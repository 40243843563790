define("ember-svg-jar/inlined/liked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M25.905 4.095c-2.794-2.793-7.41-2.793-10.204 0L14 5.796l-1.7-1.7c-2.795-2.794-7.41-2.794-10.205 0-2.793 2.793-2.793 7.41 0 10.203L3.796 16 14 26.204 24.204 16l1.7-1.7c2.794-2.795 2.794-7.29 0-10.205z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});