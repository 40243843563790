define("@latermedia/ember-later-analytics/components/best-performing-media/item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{concat "o--card " (if @isReport "tRE" "tAN") "--card"}} ...attributes>
    {{#if @isLoading}}
      <figure
        class="cCH--loading cCH--loading__image cCH--loading__image__tall"
      ></figure>
    {{else if @media.url}}
      {{#if @routeName}}
        <LinkTo @route={{@routeName}} @model={{@media.id}}>
          <BestPerformingMedia::Image
            @media={{@media}}
            @handleError={{@handleError}}
          />
        </LinkTo>
      {{else}}
        <BestPerformingMedia::Image
          @media={{@media}}
          @handleError={{@handleError}}
        />
      {{/if}}
    {{else}}
      <figure class="o--mediaContainer">
        <img class="o--media">
        <b class="o--mediaOverlay"></b>
      </figure>
    {{/if}}
    <div class="o--card__content">
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "S1CwMKqx",
    "block": "[[[11,0],[16,0,[28,[37,0],[\"o--card \",[52,[30,1],\"tRE\",\"tAN\"],\"--card\"],null]],[17,2],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,\"figure\"],[14,0,\"cCH--loading cCH--loading__image cCH--loading__image__tall\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[30,4,[\"url\"]],[[[41,[30,5],[[[1,\"      \"],[8,[39,2],null,[[\"@route\",\"@model\"],[[30,5],[30,4,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@media\",\"@handleError\"],[[30,4],[30,6]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,3],null,[[\"@media\",\"@handleError\"],[[30,4],[30,6]]],null],[1,\"\\n\"]],[]]]],[]],[[[1,\"    \"],[10,\"figure\"],[14,0,\"o--mediaContainer\"],[12],[1,\"\\n      \"],[10,\"img\"],[14,0,\"o--media\"],[12],[13],[1,\"\\n      \"],[10,\"b\"],[14,0,\"o--mediaOverlay\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]],[]]],[1,\"  \"],[10,0],[14,0,\"o--card__content\"],[12],[1,\"\\n    \"],[18,7,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@isReport\",\"&attrs\",\"@isLoading\",\"@media\",\"@routeName\",\"@handleError\",\"&default\"],false,[\"concat\",\"if\",\"link-to\",\"best-performing-media/image\",\"yield\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/best-performing-media/item.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});