define("ember-svg-jar/inlined/draggable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.625 26a2.53 2.53 0 01-1.856-.769A2.53 2.53 0 016 23.375c0-.725.256-1.344.769-1.856a2.53 2.53 0 011.856-.769 2.53 2.53 0 011.856.769 2.53 2.53 0 01.769 1.856 2.53 2.53 0 01-.769 1.856A2.53 2.53 0 018.625 26zm0-9.375a2.53 2.53 0 01-1.856-.769A2.53 2.53 0 016 14c0-.725.256-1.344.769-1.856a2.53 2.53 0 011.856-.769 2.53 2.53 0 011.856.769A2.53 2.53 0 0111.25 14a2.53 2.53 0 01-.769 1.856 2.53 2.53 0 01-1.856.769zm0-9.375a2.53 2.53 0 01-1.856-.769A2.53 2.53 0 016 4.625c0-.725.256-1.344.769-1.856A2.53 2.53 0 018.625 2a2.53 2.53 0 011.856.769 2.53 2.53 0 01.769 1.856 2.53 2.53 0 01-.769 1.856 2.53 2.53 0 01-1.856.769zm9.75 0a2.53 2.53 0 01-1.856-.769 2.53 2.53 0 01-.769-1.856c0-.725.256-1.344.769-1.856A2.53 2.53 0 0118.375 2a2.53 2.53 0 011.856.769A2.53 2.53 0 0121 4.625a2.53 2.53 0 01-.769 1.856 2.53 2.53 0 01-1.856.769zm0 9.375a2.53 2.53 0 01-1.856-.769A2.53 2.53 0 0115.75 14c0-.725.256-1.344.769-1.856a2.53 2.53 0 011.856-.769 2.53 2.53 0 011.856.769A2.53 2.53 0 0121 14a2.53 2.53 0 01-.769 1.856 2.53 2.53 0 01-1.856.769zm0 9.375a2.53 2.53 0 01-1.856-.769 2.53 2.53 0 01-.769-1.856c0-.725.256-1.344.769-1.856a2.53 2.53 0 011.856-.769 2.53 2.53 0 011.856.769A2.53 2.53 0 0121 23.375a2.53 2.53 0 01-.769 1.856 2.53 2.53 0 01-1.856.769z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "none",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});