define("ember-svg-jar/inlined/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14 .5C21.486.5 27.5 6.514 27.5 14S21.486 27.5 14 27.5.5 21.486.5 14 6.514.5 14 .5zm0 2.455A11 11 0 002.955 14 11 11 0 0014 25.045 11 11 0 0025.045 14 11 11 0 0014 2.955zm-.243 15.475c.366 0 .675.134.925.4.25.268.375.597.375.988 0 .435-.13.776-.392 1.025a1.28 1.28 0 01-.908.372c-.355 0-.665-.122-.93-.368-.265-.245-.397-.588-.397-1.029 0-.391.128-.72.384-.987s.57-.401.943-.401zm.212-11.318c.844 0 1.589.166 2.235.498.646.333 1.145.784 1.497 1.356.352.571.528 1.192.528 1.863 0 .528-.1.99-.301 1.388-.201.397-.44.74-.716 1.03-.277.288-.773.774-1.488 1.457a6.281 6.281 0 00-.476.508c-.12.146-.208.28-.266.4a2.045 2.045 0 00-.136.363c-.032.122-.08.334-.144.639-.11.645-.456.968-1.038.968-.303 0-.558-.105-.764-.316-.207-.212-.31-.525-.31-.941 0-.522.076-.974.227-1.356.151-.382.352-.717.602-1.006.25-.288.588-.631 1.013-1.029.372-.348.641-.61.807-.787.166-.177.306-.374.42-.592.113-.217.17-.453.17-.708 0-.496-.174-.916-.52-1.257-.346-.342-.793-.512-1.34-.512-.64 0-1.111.172-1.414.517-.303.344-.559.852-.768 1.523-.198.701-.573 1.052-1.126 1.052-.326 0-.6-.122-.825-.368-.224-.245-.336-.51-.336-.796 0-.59.177-1.188.532-1.793.355-.606.873-1.107 1.554-1.505.681-.397 1.475-.596 2.383-.596z\" opacity=\".9\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});