define("ember-svg-jar/inlined/add-link copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M23 0a2.333 2.333 0 012.333 2.333v23.334A2.333 2.333 0 0123 28l-9.333-5.833L4.333 28A2.333 2.333 0 012 25.667V2.333A2.333 2.333 0 014.333 0H23zm0 2.333H4.333v23.334l9.334-5.834L23 25.667V2.333zm-9.154 4.039c.727 0 1.212.484 1.212 1.211v2.423h2.423c.727 0 1.211.485 1.211 1.212s-.484 1.211-1.211 1.211h-2.423v2.424c0 .726-.485 1.211-1.212 1.211s-1.211-.485-1.211-1.211v-2.424h-2.423C9.485 12.43 9 11.945 9 11.218c0-.727.485-1.212 1.212-1.212h2.423V7.583c0-.727.484-1.211 1.211-1.211z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});