define("ember-svg-jar/inlined/star-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M37.066 27.661L51 25.989l-13.934-1.657a10.748 10.748 0 01-9.387-9.383L26 1l-1.68 13.928a10.74 10.74 0 01-9.386 9.404L1 26.01l13.934 1.671a10.755 10.755 0 019.386 9.39L26 51l1.68-13.928a10.746 10.746 0 019.386-9.411v0z\" stroke=\"currentcolor\" stroke-width=\"3\"/>",
    "attrs": {
      "width": "52",
      "height": "52",
      "viewBox": "0 0 52 52",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});