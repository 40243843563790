define("@latermedia/ember-later-analytics/components/ui/meter-list-loading", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div data-test-id="meter-list-loading">
    <div class="cCH--loading cCH--loading--line"></div>
    <div class="cCH--loading cCH--loading--line"></div>
    <div class="cCH--loading cCH--loading--line"></div>
    <div class="cCH--loading cCH--loading--line"></div>
    <div class="cCH--loading cCH--loading--line"></div>
  </div>
  
  */
  {
    "id": "8NJWWBxQ",
    "block": "[[[10,0],[14,\"data-test-id\",\"meter-list-loading\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"cCH--loading cCH--loading--line\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"cCH--loading cCH--loading--line\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"cCH--loading cCH--loading--line\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"cCH--loading cCH--loading--line\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"cCH--loading cCH--loading--line\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/meter-list-loading.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});