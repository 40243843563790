define("ember-svg-jar/inlined/social-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M15.167 15A5.834 5.834 0 0121 20.834v4.333a1.167 1.167 0 01-2.333 0v-4.333a3.5 3.5 0 00-3.5-3.5H5.833a3.5 3.5 0 00-3.5 3.5v4.333a1.167 1.167 0 11-2.333 0v-4.333A5.834 5.834 0 015.833 15h9.334zm7.037 0a5.833 5.833 0 015.833 5.833v4.334a1.166 1.166 0 11-2.333 0v-4.334a3.5 3.5 0 00-3.5-3.5h-.595A7.38 7.38 0 0019.593 15zM17.537 1a5.832 5.832 0 015.833 5.834 5.832 5.832 0 01-5.833 5.833 5.822 5.822 0 01-2.126-.405 7.373 7.373 0 001.544-1.987c.19.032.383.059.582.059a3.5 3.5 0 100-7c-.194 0-.38.027-.566.057a7.363 7.363 0 00-1.558-1.986A5.81 5.81 0 0117.537 1zM10.5 1a5.833 5.833 0 110 11.666A5.833 5.833 0 0110.5 1zm0 2.334a3.5 3.5 0 100 7 3.5 3.5 0 000-7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});