define("ember-svg-jar/inlined/beta-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.575 2.882C2.033.756 3.688 0 4.98 0c1.547 0 2.408.742 2.408 1.94 0 1.199-1.09 2.04-2.125 2.212l.046.021c.53.244 1.366.629 1.366 1.848 0 1.612-1.44 2.696-2.785 2.696-.82 0-1.453-.314-1.856-.913l-.673 2.64c-.08.313-.363.556-.672.556a.675.675 0 01-.539-.271c-.134-.171-.174-.4-.134-.614l1.56-7.233zm2.113 1.427c0-.371.269-.685.618-.685h-.013c.942-.014 1.56-.557 1.56-1.356 0-.552-.352-.941-1.13-.941-.778 0-1.533.628-1.735 1.526l-.807 3.781c.269.5.78.77 1.399.77.995 0 1.695-.599 1.695-1.469 0-.57-.417-.956-1.063-.984-.39-.057-.524-.371-.524-.642z\"/><path d=\"M9.701 1.955h3.175c.31 0 .578.256.578.613 0 .328-.269.6-.578.6h-2.718l-.296 1.455h2.382c.309 0 .578.256.578.613 0 .328-.269.6-.578.6h-2.65L9.27 7.39h2.448c.31 0 .578.286.578.614s-.255.613-.578.613H8.477c-.498 0-.74-.314-.632-.827L8.92 2.625c.094-.442.363-.67.78-.67zM19.24 2.582c0-.342-.27-.613-.58-.613H14.72c-.323 0-.592.285-.592.613 0 .329.27.628.592.628h1.197l-.955 4.637c-.107.456.215.856.646.856.31 0 .578-.228.646-.542l1.022-4.95h1.413c.323 0 .578-.286.578-.629h-.027z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22.078 1.898c.578 0 1.049.442 1.13 1.027l-.014-.029.78 4.937c.068.456-.255.841-.672.841a.674.674 0 01-.673-.613l-.08-.642h-2.812l-.484.913a.672.672 0 01-.579.357c-.35 0-.646-.3-.646-.685 0-.129.054-.243.108-.357l2.946-5.165c.215-.342.579-.584.996-.584zm-.054 1.284L20.41 6.206h1.99l-.376-3.024z\"/><defs><linearGradient id=\"betaLabelGradient\" x1=\"0\" y1=\"11\" x2=\"24\" y2=\"0\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#4443CB\"/><stop offset=\"1\" stop-color=\"#918FFF\"/></linearGradient></defs>",
    "attrs": {
      "width": "24",
      "height": "11",
      "viewBox": "0 0 24 11",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});