define("ember-svg-jar/inlined/lib--ungroup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#a)\"><path fill-rule=\"evenodd\" d=\"M6.535 20.127h1.338v1.338a3.35 3.35 0 104.219 4.22h9.373A3.35 3.35 0 0028 24.65a3.35 3.35 0 00-2.316-3.186v-9.373a3.35 3.35 0 00-1.033-6.535 3.35 3.35 0 00-3.186 2.316h-1.338V6.535a3.35 3.35 0 10-4.219-4.22H6.535a3.35 3.35 0 10-4.22 4.22v9.373a3.35 3.35 0 104.22 4.22zm13.592-4.219v-5.97h1.338a3.357 3.357 0 002.153 2.154v9.373a3.357 3.357 0 00-2.153 2.153h-9.373a3.357 3.357 0 00-2.153-2.153v-1.338h5.969a3.35 3.35 0 006.535-1.033 3.35 3.35 0 00-2.316-3.186zM2.067 3.348a1.282 1.282 0 112.564 0 1.282 1.282 0 01-2.565 0zm15.745 0a1.282 1.282 0 112.565 0 1.282 1.282 0 01-2.565 0zM4.382 6.536a3.357 3.357 0 002.153-2.153h9.373a3.357 3.357 0 002.153 2.153v9.373a3.357 3.357 0 00-2.153 2.153H6.535a3.357 3.357 0 00-2.153-2.153V6.535zm18.987 2.37a1.282 1.282 0 112.565 0 1.282 1.282 0 01-2.565 0zM2.066 19.096a1.282 1.282 0 112.565 0 1.282 1.282 0 01-2.565 0zm15.746 0a1.282 1.282 0 112.564 0 1.282 1.282 0 01-2.564 0zM7.623 24.65a1.282 1.282 0 112.565 0 1.282 1.282 0 01-2.565 0zm15.746 0a1.283 1.283 0 112.565 0 1.283 1.283 0 01-2.565 0z\" clip-rule=\"evenodd\"/></g><defs><clipPath id=\"a\"><path d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});