define("ember-svg-jar/inlined/schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M-5.351-26.565a1.54 1.54 0 01-.004 2.188l-8.419 8.418 8.419 8.42c.571.571.608 1.473.1 2.083l-.096.104a1.54 1.54 0 01-2.188-.004l-8.419-8.419-8.419 8.42a1.544 1.544 0 01-2.083.099l-.105-.096a1.54 1.54 0 01.004-2.188l8.419-8.42-8.419-8.417a1.544 1.544 0 01-.1-2.084l.096-.104a1.54 1.54 0 012.188.004l8.419 8.418 8.419-8.418a1.544 1.544 0 012.083-.1l.105.096zM14 0C6.236 0 0 6.236 0 14s6.236 14 14 14 14-6.236 14-14S21.764 0 14 0zm0 25.455A11.407 11.407 0 012.545 14C2.545 7.636 7.636 2.545 14 2.545S25.455 7.636 25.455 14 20.364 25.455 14 25.455zm4.71-8.528c.508.51.508 1.273 0 1.782a1.375 1.375 0 01-.892.382c-.254 0-.636-.127-.89-.382l-3.819-3.818c-.254-.255-.382-.51-.382-.891V6.364c0-.764.51-1.273 1.273-1.273.764 0 1.273.509 1.273 1.273v7.127l3.436 3.436z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});