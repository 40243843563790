define("ember-svg-jar/inlined/Instagram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.49 7.998a3.509 3.509 0 107.016 0 3.509 3.509 0 00-7.017 0zm5.922 0a2.414 2.414 0 11-4.828 0 2.414 2.414 0 014.828 0zM10.904 4.422a.924.924 0 101.848 0 .924.924 0 00-1.848 0zm.91 0a.014.014 0 11.028 0 .014.014 0 01-.028 0z\" fill=\"#323B43\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.557 1.04A3.517 3.517 0 001.04 4.557v6.882a3.517 3.517 0 003.517 3.516h6.882a3.517 3.517 0 003.516-3.516V4.557A3.517 3.517 0 0011.44 1.04H4.557zM2.134 11.439V4.557a2.422 2.422 0 012.423-2.422h6.882a2.422 2.422 0 012.422 2.422v6.882a2.422 2.422 0 01-2.422 2.422H4.557a2.422 2.422 0 01-2.423-2.422z\" fill=\"#323B43\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});