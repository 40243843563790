define("ember-svg-jar/inlined/lib--buttonGroup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.247 10.94a6.668 6.668 0 00-.77-2.934l.52-.506c2.06-2 5.4-2 7.46 0a5.022 5.022 0 010 7.245l-2.087 2.027a1.368 1.368 0 01-1.895 0 1.276 1.276 0 010-1.841l2.086-2.027a2.477 2.477 0 000-3.563 2.657 2.657 0 00-3.668 0l-1.646 1.598zm-7.476 10.275l.555-.54a2.954 2.954 0 00.89-1.836c.052-.005.103-.01.153-.018.126.306.316.592.57.838 1.008.98 2.66.98 3.668 0l2.086-2.026a1.368 1.368 0 011.895 0 1.275 1.275 0 010 1.84L19.502 21.5c-2.058 2-5.4 2-7.458 0a5.189 5.189 0 01-.273-.285z\"/><path fill-rule=\"evenodd\" d=\"M6.412 9.526L8.498 7.5c2.058-2 5.4-2 7.458 0a5.022 5.022 0 010 7.245l-2.086 2.027a1.368 1.368 0 01-1.895 0 1.276 1.276 0 010-1.841l2.086-2.027a2.477 2.477 0 000-3.563 2.657 2.657 0 00-3.668 0l-2.086 2.026a1.368 1.368 0 01-1.895 0 1.276 1.276 0 010-1.84zm.695 10.133l2.086-2.026a1.368 1.368 0 011.895 0 1.275 1.275 0 010 1.84L9.002 21.5c-2.058 2-5.4 2-7.458 0a5.022 5.022 0 010-7.245l2.086-2.027a1.368 1.368 0 011.895 0 1.276 1.276 0 010 1.841L3.44 16.096a2.477 2.477 0 000 3.563c1.008.98 2.66.98 3.668 0zm-1.39-4.053l4.172-4.053a1.368 1.368 0 011.895 0 1.275 1.275 0 010 1.84L7.61 17.448a1.368 1.368 0 01-1.895 0 1.275 1.275 0 010-1.84z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M10.28 17.259a1.358 1.358 0 00-1.087.374l-2.086 2.026c-1.008.98-2.66.98-3.668 0a2.477 2.477 0 010-3.563l2.086-2.027a1.278 1.278 0 000-1.84 1.354 1.354 0 00-.9-.38 1.358 1.358 0 00-.995.38l-2.086 2.026a5.022 5.022 0 000 7.245c2.058 2 5.4 2 7.458 0l2.086-2.026a1.278 1.278 0 000-1.841 1.35 1.35 0 00-.808-.374zm-4.564-1.653a1.277 1.277 0 000 1.841c.226.22.513.344.808.374.388.04.79-.085 1.087-.374l4.173-4.053a1.277 1.277 0 000-1.841 1.351 1.351 0 00-.808-.374c-.388-.04-.79.085-1.087.374l-4.173 4.053z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});