define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M25.667 28H2.333A2.333 2.333 0 010 25.667v-21a2.333 2.333 0 012.333-2.334h2.334v2.334H2.333v21h23.334v-21h-2.334V2.333h2.334A2.333 2.333 0 0128 4.667v21A2.333 2.333 0 0125.667 28zm-5.834-8.167h2.334a1.167 1.167 0 010 2.334h-2.334a1.167 1.167 0 010-2.334zm0-4.666h2.334a1.167 1.167 0 110 2.333h-2.334a1.167 1.167 0 010-2.333zm2.334-2.334h-2.334a1.167 1.167 0 110-2.333h2.334a1.167 1.167 0 010 2.333zM21 7a1.167 1.167 0 01-1.167-1.167V1.167a1.167 1.167 0 012.334 0v4.666C22.167 6.478 21.644 7 21 7zm-4.667-4.667h2.334v2.334h-2.334V2.333zm-3.5 17.5h2.334a1.167 1.167 0 010 2.334h-2.334a1.167 1.167 0 010-2.334zm0-4.666h2.334a1.167 1.167 0 110 2.333h-2.334a1.167 1.167 0 010-2.333zm2.334-2.334h-2.334a1.167 1.167 0 110-2.333h2.334a1.167 1.167 0 010 2.333zM14 7a1.167 1.167 0 01-1.167-1.167V1.167a1.167 1.167 0 012.334 0v4.666C15.167 6.478 14.644 7 14 7zM9.333 2.333h2.334v2.334H9.333V2.333zm-3.5 17.5h2.334a1.167 1.167 0 010 2.334H5.833a1.167 1.167 0 010-2.334zm0-4.666h2.334a1.167 1.167 0 110 2.333H5.833a1.167 1.167 0 010-2.333zm2.334-2.334H5.833a1.167 1.167 0 110-2.333h2.334a1.167 1.167 0 010 2.333zM7 7a1.167 1.167 0 01-1.167-1.167V1.167a1.167 1.167 0 112.334 0v4.666C8.167 6.478 7.644 7 7 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});