define("@latermedia/ember-later-analytics/utils/generate-stats-tile-grid", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateStatsTileGrid = _exports.default = void 0;

  /**
   * Organizes an array of stats tiles into the given column size (making a grid)
   *
   * @param tiles Array of tiles to be organized
   * @param numberOfColumns Number of columns to be used in the grid
   * @returns New stat tile grid
   */
  const generateStatsTileGrid = (tiles, numberOfColumns) => {
    if ((0, _utils.isEmpty)(tiles) || !numberOfColumns) {
      return [];
    }

    const numberOfRows = Math.ceil(tiles.length / numberOfColumns);
    const emptyRows = Array(numberOfRows).fill(null);
    const requiredTileLength = numberOfColumns * numberOfRows;
    const allTiles = [...tiles, ...Array(requiredTileLength - tiles.length).fill(null)];
    return emptyRows.map((_row, columnIndex) => {
      const startingIndex = columnIndex * numberOfColumns;
      return allTiles.slice(startingIndex, startingIndex + numberOfColumns);
    });
  };

  _exports.generateStatsTileGrid = generateStatsTileGrid;
  var _default = generateStatsTileGrid;
  _exports.default = _default;
});