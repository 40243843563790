define("ember-svg-jar/inlined/pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.184 2.756a.449.449 0 01-.135-.318c0-.118.047-.231.135-.319L7.382.921c.45-.43 1.163-.43 1.595.002l3.102 3.1c.436.456.43 1.17-.017 1.598l-1.197 1.196a.449.449 0 01-.318.135.449.449 0 01-.318-.135l-.272-.272-1.215 1.216v1.704c0 .118-.048.231-.135.319L7.41 10.98a.449.449 0 01-.318.135.449.449 0 01-.318-.135l-2.06-2.058L.87 12.767a.449.449 0 01-.318.135.449.449 0 01-.318-.135.442.442 0 010-.636L4.08 8.269 2.02 6.211a.442.442 0 010-.637l1.197-1.196a.449.449 0 01.318-.135H5.24l1.216-1.216-.272-.271zm-.896 1.487l-.082.034.034-.034h.048zM7.87 9.249l-.034.082v-.048l.034-.034z\"/>",
    "attrs": {
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});