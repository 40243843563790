define("ember-svg-jar/inlined/tones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.089 11.466A5.002 5.002 0 0013.82 1.63 5.002 5.002 0 008.983 5.36a7.88 7.88 0 00-1.431-.16 6.429 6.429 0 117.697 7.697 7.883 7.883 0 00-.16-1.431z\"/><path d=\"M13.644 8.062a3.47 3.47 0 01.177-.005c.591 0 1.018.147 1.288.282.136.068.234.134.293.178.28.272.79.322 1.067.045a.717.717 0 00-.002-1.012l-.066-.061a3.35 3.35 0 00-.653-.428 4.279 4.279 0 00-1.927-.433c-.545 0-1.008.09-1.388.213.449.36.855.769 1.211 1.22z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.393 18.771a5.714 5.714 0 100-11.428 5.714 5.714 0 000 11.428zm0 1.429a7.143 7.143 0 100-14.286 7.143 7.143 0 000 14.286z\"/><path d=\"M5.964 11.271a1.071 1.071 0 11-2.143 0 1.071 1.071 0 012.143 0zM10.964 11.271a1.071 1.071 0 11-2.143 0 1.071 1.071 0 012.143 0zM4.744 14.67c.276-.276.788-.226 1.067.046.06.044.157.11.293.178.27.134.697.281 1.288.281.59 0 1.018-.147 1.288-.281.136-.068.233-.134.292-.178.28-.272.791-.322 1.068-.046a.714.714 0 010 1.01l-.068.064c-.012.01.017-.016 0 0a3.357 3.357 0 01-.653.428 4.28 4.28 0 01-1.927.432 4.28 4.28 0 01-1.927-.433 3.358 3.358 0 01-.653-.427c-.017-.016.142.114 0 0l-.066-.062a.717.717 0 01-.002-1.012zM10.25 4.486c0-.395.32-.715.714-.715h1.072a.714.714 0 110 1.429h-1.072a.714.714 0 01-.714-.714zM14.893 4.486c0-.395.32-.715.714-.715h1.072a.714.714 0 110 1.429h-1.072a.714.714 0 01-.714-.714z\"/>",
    "attrs": {
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});