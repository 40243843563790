define("@latermedia/ember-later-analytics/utils/convert-meta-to-string", ["exports", "@latermedia/ember-later-analytics/utils/format-number-with-suffix", "@latermedia/ember-later-analytics/utils/precise-round"], function (_exports, _formatNumberWithSuffix, _preciseRound) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Utils
   */

  /**
   * @class ConvertMetaToStringUtil
   * @extends Utils
   */

  /**
   * Converts a metadata object to a formatted string.
   *
   * @method convertMetaToString
   * @param {Object} obj Object containing meta data information
   * @param {Number} obj.value Numerical value
   * @param {Boolean} obj.isIncreasing Boolean to indicate if value is increasing
   * @param {Boolean} obj.isPercent Boolean to indicate if value is a percentage
   * @param {Boolean} [applyformatNumberWithSuffix=true] Boolean to indicate if obj.value should be rounded using @formatNumberWithSuffix
   * @param {Number} [significantDigits=null] Number of significant digits to use if formatNumberWithSuffix is false
   * @param {Boolean} [showSign=false] Boolean to indicate whether to show +/- as a prefix
   *
   * @return {String} Formatted string, including sign, obj.value and percent
   */
  const convertMetaToString = function (obj) {
    let applyformatNumberWithSuffix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    let significantDigits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    let showSign = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    const positiveNumber = Math.abs(obj.value ? obj.value : 0);
    let numberAsStr = `${positiveNumber}`;

    if (applyformatNumberWithSuffix) {
      numberAsStr = (0, _formatNumberWithSuffix.default)(positiveNumber);
    } else {
      numberAsStr = significantDigits ? (0, _preciseRound.default)(Number(numberAsStr), significantDigits) : Number(numberAsStr).toLocaleString();
    }

    const positiveSign = showSign ? '+ ' : '';
    const negativeSign = '- ';
    const sign = obj.isIncreasing ? positiveSign : negativeSign;
    const percent = obj.isPercent ? '%' : '';
    return `${sign + numberAsStr + percent}`;
  };

  var _default = convertMetaToString;
  _exports.default = _default;
});