define("ember-engines/-private/route-ext", ["@ember/routing/route", "@ember/application"], function (_route, _application) {
  "use strict";

  /*
    Creates an aliased form of a method that properly resolves external routes.
  */
  function externalAlias(methodName) {
    return function _externalAliasMethod(routeName) {
      let externalRoute = (0, _application.getOwner)(this)._getExternalRoute(routeName);

      let router = this._router || this.router;

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return router[methodName](externalRoute, ...args);
    };
  }

  _route.default.reopen({
    transitionToExternal: externalAlias('transitionTo'),
    replaceWithExternal: externalAlias('replaceWith')
  });
});