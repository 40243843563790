define("ember-svg-jar/inlined/gather", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14 23.817L2.567 17.983 0 19.383 14 26.5l14-7.117-2.567-1.4L14 23.817zm0-5.134L2.567 12.85 0 14.25l14 7.117 14-7.117-2.567-1.4L14 18.683zm14-9.566L14 2 0 9.117l14 7.116 14-7.116zM14 4.683l8.75 4.55L14 13.667l-8.75-4.55L14 4.683z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});