define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M27.895 10.44a2.036 2.036 0 00-1.61-1.365l-7.292-1.118-3.161-6.788a2.02 2.02 0 00-3.663 0L9.007 7.955 1.716 9.073A2.038 2.038 0 00.573 12.5l5.343 5.502-1.237 7.633a2.022 2.022 0 002.975 2.108L14 24.214l6.347 3.53c.3.165.637.252.98.255a2.026 2.026 0 001.994-2.363l-1.236-7.633 5.343-5.502a2.036 2.036 0 00.467-2.061zm-7.432 6.085a1.958 1.958 0 00-.536 1.703l1.201 7.447-6.171-3.442a1.966 1.966 0 00-1.913 0l-6.172 3.442 1.202-7.447a1.958 1.958 0 00-.537-1.703l-5.191-5.366 7.093-1.092a1.983 1.983 0 001.481-1.118L14 2.33l3.08 6.621a1.983 1.983 0 001.482 1.117l7.093 1.093-5.192 5.365z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});