define("ember-svg-jar/inlined/thumbs-down-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M22.94 1.333a3.695 3.695 0 013.718 3.2.994.994 0 01.009.134v8.166c0 .045-.003.09-.01.134a3.695 3.695 0 01-3.717 3.2h-2.457l-4.402 9.906a1 1 0 01-.914.594 4.5 4.5 0 01-4.5-4.5V18.5H5.069a3.335 3.335 0 01-3.328-3.833l1.61-10.5A3.336 3.336 0 016.68 1.333h16.26z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});