define("ember-svg-jar/inlined/edit-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M11.415 6.124l.118.073 3.285 2.302L18.5 7.065a1.09 1.09 0 011.473 1.151l-.024.132-1.006 3.984 2.619 3.21a1.086 1.086 0 01-.68 1.763l-.136.012-3.89.062-1.999 3.338a1.083 1.083 0 01-1.895-.068l-.05-.114-1.009-2.656-9.685 9.686c-.43.43-1.12.463-1.589.087l-.096-.086-.083-.083a1.19 1.19 0 01-.087-1.589l.087-.097 9.545-9.545-2.506-.71c-.347-.1-.62-.364-.734-.701l-.035-.13a1.092 1.092 0 01.282-.975l.1-.091 3.083-2.472-.351-3.995a1.082 1.082 0 011.58-1.054zm8.75 16.126a.36.36 0 01.388.017l1.095.768 1.227-.478a.364.364 0 01.483.427l-.335 1.328.872 1.07a.362.362 0 01-.272.592l-1.296.02-.666 1.113a.361.361 0 01-.649-.06l-.49-1.291-1.317-.374a.364.364 0 01-.13-.632l1.028-.824-.117-1.332a.36.36 0 01.179-.344zM14.05 23a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zm10.6-6.2a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zm-21.4-3.3a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zm21.664-5.66a.364.364 0 01.369.083l.942.92 1.308-.279a.36.36 0 01.394.522l-.629 1.18.624 1.16a.364.364 0 01-.365.532l-1.36-.17-.955.996a.362.362 0 01-.62-.198l-.179-1.284-1.185-.526a.361.361 0 01-.02-.65l1.222-.645.21-1.353a.364.364 0 01.244-.288zM4.274 5.132l.08.02a.36.36 0 01.237.307l.116 1.332 1.155.633a.364.364 0 01-.018.645l-1.232.597-.259 1.356a.362.362 0 01-.628.172l-.849-.98-1.28.205a.361.361 0 01-.37-.535l.673-1.206-.56-1.25a.364.364 0 01.4-.505l1.292.258.945-.945a.36.36 0 01.379-.085zM22.25 3a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zM17.119.178a.36.36 0 01.522.393l-.279 1.308.92.943a.364.364 0 01-.205.611l-1.353.21-.644 1.222a.362.362 0 01-.651-.019l-.526-1.185-1.284-.179a.361.361 0 01-.198-.62l.997-.956-.171-1.359a.364.364 0 01.532-.366l1.16.625zM8.75 2a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});