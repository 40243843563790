define("ember-svg-jar/inlined/trending-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M27.907 6.558a1.166 1.166 0 00-.63-.63c-.14-.06-.291-.091-.443-.093h-6.999a1.166 1.166 0 100 2.333h4.188l-8.27 8.27-5.004-5.016a1.166 1.166 0 00-1.656 0L.345 20.17a1.166 1.166 0 000 1.656 1.166 1.166 0 001.656 0l7.92-7.931 5.004 5.015a1.166 1.166 0 001.656 0l9.086-9.098V14A1.166 1.166 0 0028 14V7a1.166 1.166 0 00-.093-.443z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});