define("ember-svg-jar/inlined/pin-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M12.727 0C13.491 0 14 .51 14 1.273c0 .763-.51 1.272-1.273 1.272H3.818c-.763 0-1.273.51-1.273 1.273v20.364c0 .763.51 1.273 1.273 1.273h20.364c.763 0 1.273-.51 1.273-1.273v-8.91c0-.763.509-1.272 1.272-1.272.764 0 1.273.51 1.273 1.273v8.909C28 26.345 26.345 28 24.182 28H3.818C1.655 28 0 26.345 0 24.182V3.818C0 1.655 1.655 0 3.818 0zm6.775.75c.219-.815.884-.994 1.48-.397l6.686 6.685c.596.596.417 1.263-.397 1.48l-2.033.147-6.425 6.426-.31 4.312c-.278 1.036-1.126 1.264-1.885.505l-3.578-3.579-5.726 5.706-.022.007c-1.055.328-1.64-.26-1.313-1.313l.006-.021 5.707-5.727-2.456-2.456-1.122-1.122c-.76-.76-.532-1.608.504-1.885l4.313-.31 6.425-6.425z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});