define("ember-code-snippet/-private/unindent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = unindent;

  function unindent(src) {
    let match,
        min,
        lines = src.split("\n").filter(l => l !== '');

    for (let i = 0; i < lines.length; i++) {
      match = /^[ \t]*/.exec(lines[i]);

      if (match && (typeof min === 'undefined' || min > match[0].length)) {
        min = match[0].length;
      }
    }

    if (typeof min !== 'undefined' && min > 0) {
      src = src.replace(new RegExp("^[ \t]{" + min + "}", 'gm'), "");
    }

    return src;
  }
});