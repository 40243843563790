define("@latermedia/ember-later-analytics/components/report-stats-tile-grid", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@latermedia/ember-later-analytics/utils/generate-stats-tile-grid"], function (_exports, _component, _templateFactory, _component2, _generateStatsTileGrid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{concat "cCH--statGrid " (if @isTop "cCH--statGrid--top")}} data-test-id="stats-tile-grid">
    {{yield}}
    {{#each this.tileRows as |rowValues|}}
      {{#each rowValues as |item|}}
        {{#if item}}
          <StatsTileItem
            @name={{item.name}}
            @main={{item.main}}
            @tooltip={{item.main.tooltip}}
            @significantDigits={{if item.main.isPercent @significantDigitsPercentage @significantDigitsCount}}
            @trend={{item.trend}}
            @additionalClasses={{@additionalClasses}}
            @isSmall={{@isSmall}}
            @glossaryLink={{@glossaryLink}}
          >
            {{svg-jar "help" class="o--icon--sm o--icon--light u--m__l__xs"}}
          </StatsTileItem>
        {{else}}
          <StatsTileItem @isEmpty={{true}} />
        {{/if}}
      {{/each}}
    {{/each}}
  </div>
  
  */
  {
    "id": "zNBCFi/A",
    "block": "[[[10,0],[15,0,[28,[37,0],[\"cCH--statGrid \",[52,[30,1],\"cCH--statGrid--top\"]],null]],[14,\"data-test-id\",\"stats-tile-grid\"],[12],[1,\"\\n  \"],[18,9,null],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"tileRows\"]]],null]],null],null,[[[42,[28,[37,4],[[28,[37,4],[[30,2]],null]],null],null,[[[41,[30,3],[[[1,\"        \"],[8,[39,5],null,[[\"@name\",\"@main\",\"@tooltip\",\"@significantDigits\",\"@trend\",\"@additionalClasses\",\"@isSmall\",\"@glossaryLink\"],[[30,3,[\"name\"]],[30,3,[\"main\"]],[30,3,[\"main\",\"tooltip\"]],[52,[30,3,[\"main\",\"isPercent\"]],[30,4],[30,5]],[30,3,[\"trend\"]],[30,6],[30,7],[30,8]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,6],[\"help\"],[[\"class\"],[\"o--icon--sm o--icon--light u--m__l__xs\"]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,5],null,[[\"@isEmpty\"],[true]],null],[1,\"\\n\"]],[]]]],[3]],null]],[2]],null],[13],[1,\"\\n\"]],[\"@isTop\",\"rowValues\",\"item\",\"@significantDigitsPercentage\",\"@significantDigitsCount\",\"@additionalClasses\",\"@isSmall\",\"@glossaryLink\",\"&default\"],false,[\"concat\",\"if\",\"yield\",\"each\",\"-track-array\",\"stats-tile-item\",\"svg-jar\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/report-stats-tile-grid.hbs",
    "isStrictMode": false
  });

  class ReportStatsTileGridComponent extends _component2.default {
    get tileRows() {
      const tileValues = this.args.tiles ? Object.values(this.args.tiles) : null;

      if (tileValues) {
        return (0, _generateStatsTileGrid.default)(tileValues, this.args.numberOfColumns);
      }

      return [];
    }

  }

  _exports.default = ReportStatsTileGridComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ReportStatsTileGridComponent);
});