define("@latermedia/ember-later-analytics/utils/industry-benchmarks", ["exports", "@latermedia/ember-later-analytics/utils/object-methods"], function (_exports, _objectMethods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const formatBenchmarks = benchmarkData => {
    const template = {
      industryAverageEngagement: '',
      industryName: ''
    };
    const formattedBenchmarks = (0, _objectMethods.objectSnakeToCamel)(benchmarkData, template);
    formattedBenchmarks.industryEngagementPercentage = Number(formattedBenchmarks.industryAverageEngagement) * 100;
    return formattedBenchmarks;
  };

  var _default = formatBenchmarks;
  _exports.default = _default;
});