define("ember-svg-jar/inlined/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.825 16.801c.975 2.492 3.467 4.334 6.175 4.334 2.71 0 5.2-1.842 6.067-4.334h4.767v7.584c0 .65-.434 1.084-1.084 1.084H4.25c-.65 0-1.083-.542-1.083-1.084V16.8h4.658zm15.043-6.299v3.034l1.1 1.099h-5.634c0 2.166-1.95 4.334-4.334 4.334-2.383 0-4.333-2.168-4.333-4.334H4.033l1.502-1.501V10.1L1 14.635v9.75c0 1.842 1.517 3.25 3.25 3.25h19.5c1.842 0 3.25-1.408 3.25-3.25v-9.75l-4.132-4.133z\"/><path d=\"M12.917 14.285c0 .65.432 1.083 1.082 1.083.65 0 1.084-.433 1.084-1.083V4.683l3.576 3.575a1.046 1.046 0 001.516 0 1.046 1.046 0 000-1.516l-5.416-5.418a1.174 1.174 0 00-.76-.324c-.216 0-.54.108-.758.324L7.825 6.742a1.046 1.046 0 000 1.516 1.046 1.046 0 001.516 0l3.576-3.575v9.602z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});