define("@latermedia/ember-later-analytics/helpers/get-state-text", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(_ref => {
    let [state] = _ref;
    return state?.substate?.text || state?.text;
  });

  _exports.default = _default;
});