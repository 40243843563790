define("ember-svg-jar/inlined/lib-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M22 22H6a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1v-1a1 1 0 00-1-1zm0-2a3 3 0 013 3v1a3 3 0 01-3 3H6a3 3 0 01-3-3v-1a3 3 0 013-3h16zm0-9H6a3 3 0 00-3 3v1a3 3 0 003 3h16a3 3 0 003-3v-1a3 3 0 00-3-3zm0 2H6a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1v-1a1 1 0 00-1-1zm0-9H6a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1V5a1 1 0 00-1-1zM6 2a3 3 0 00-3 3v1a3 3 0 003 3h16a3 3 0 003-3V5a3 3 0 00-3-3H6z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});