define("ember-svg-jar/inlined/igtv-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14 18.24a1.22 1.22 0 01-.74-.25 1.24 1.24 0 01-.49-1v-1.24L8.28 17a1.23 1.23 0 11-.66-2.37L13.67 13a1.2 1.2 0 011.07.2 1.23 1.23 0 01.49 1v1.26l4.49-1.25a1.23 1.23 0 11.66 2.37l-6 1.68a1.26 1.26 0 01-.38-.02zm12.31-7.68v10.26A7.18 7.18 0 0119.13 28H8.87a7.18 7.18 0 01-7.18-7.18V10.56a7.19 7.19 0 017.18-7.18h2.47L10.05 2.1A1.23 1.23 0 1111.79.36L14 2.57 16.21.36a1.24 1.24 0 011.79 0 1.23 1.23 0 010 1.74l-1.33 1.28h2.46a7.19 7.19 0 017.18 7.18zm-2.46 0a4.72 4.72 0 00-4.72-4.71H8.87a4.72 4.72 0 00-4.72 4.71v10.26a4.73 4.73 0 004.72 4.72h10.26a4.73 4.73 0 004.72-4.72z\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});