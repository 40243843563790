define("@latermedia/ember-later-analytics/utils/constants/hours-of-the-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Utils
   */

  /**
   * @class HoursOfTheDayUtil
   * @extends Utils
   *
   * List of all hours in a day in regular (not military) time
   *
   * @property HOURS_OF_THE_DAY
   * @returns {Array.<String>}
   */
  const HOURS_OF_THE_DAY = ['12AM', '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM', '9AM', '10AM', '11AM', '12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM', '11PM'];
  var _default = HOURS_OF_THE_DAY;
  _exports.default = _default;
});