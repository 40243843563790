define("ember-svg-jar/inlined/comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M4.545 3.364c-.709 0-1.181.472-1.181 1.181v13c0 .71.472 1.182 1.181 1.182H14.71l5.2 3.664V18.727h3.546c.709 0 1.181-.472 1.181-1.182v-13c0-.709-.472-1.181-1.181-1.181H4.545zm0-2.364h18.91C25.464 1 27 2.536 27 4.545v13c0 2.01-1.536 3.546-3.545 3.546h-1.182V27L14 21.09H4.545C2.536 21.09 1 19.556 1 17.546v-13C1 2.536 2.536 1 4.545 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});