define("@latermedia/ember-later-analytics/utils/constants/days-of-the-week", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Utils
   */

  /**
   * @class DaysOfTheWeekUtil
   * @extends Utils
   *
   * List of all days of the week
   *
   * @property DAYS_OF_THE_WEEK
   * @returns {Array.<String>}
   */
  const DAYS_OF_THE_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  var _default = DAYS_OF_THE_WEEK;
  _exports.default = _default;
});