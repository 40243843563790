define("ember-svg-jar/inlined/collabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M16.695 4.858l2.309-.854.855-2.308A1.814 1.814 0 0121.575.5c.774 0 1.448.469 1.717 1.196l.855 2.308 2.308.854a1.814 1.814 0 011.195 1.717c0 .774-.469 1.448-1.195 1.717l-2.309.855-.854 2.307a1.814 1.814 0 01-1.717 1.196 1.814 1.814 0 01-1.717-1.196l-.855-2.307-2.307-.855A1.814 1.814 0 0115.5 6.575c0-.773.469-1.448 1.195-1.717zm4.88-1.354l-.537 1.451a1.825 1.825 0 01-1.082 1.082l-1.452.538 1.452.538c.503.186.897.58 1.082 1.083l.537 1.45.538-1.451c.183-.5.578-.896 1.08-1.082l1.453-.538-1.452-.538a1.825 1.825 0 01-1.082-1.084l-.537-1.449zm-7.2-1.654a1.237 1.237 0 110 2.475H5.713c-.684 0-1.238.554-1.238 1.237v18.226c0 .683.554 1.237 1.238 1.237h.877a4.653 4.653 0 014.652-4.606h4.653a4.653 4.653 0 014.653 4.606h.69c.683 0 1.237-.554 1.237-1.237v-8.663a1.237 1.237 0 112.475 0v8.663a3.712 3.712 0 01-3.712 3.712H5.713A3.712 3.712 0 012 23.788V5.562A3.712 3.712 0 015.713 1.85h8.662zm3.846 23.175a2.326 2.326 0 00-2.326-2.28h-4.652a2.326 2.326 0 00-2.326 2.28h9.304zM13.57 12.276a2.559 2.559 0 100 5.118 2.559 2.559 0 000-5.118zm-4.885 2.56a4.885 4.885 0 119.77 0 4.885 4.885 0 01-9.77 0zm-.272-8.261a1.688 1.688 0 100 3.375 1.688 1.688 0 000-3.375z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});