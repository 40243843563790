define("ember-svg-jar/inlined/media-unlink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M7.3 10.8c-1.5 0-2.7-1.2-2.7-2.7 0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7 0 1.5-1.3 2.7-2.7 2.7m7.6-9c-.2-.5-.6-.8-1.1-.8H4.5C2.5 1 1 2.5 1 4.5v18.9c0 2 1.5 3.5 3.5 3.5H5c.2 0 .4-.1.6-.2.3-.1.5-.4.6-.7L15 2.6c.1-.2.1-.6-.1-.8m9.6 21.7c0 .7-.4 1.2-1.1 1.2h-7.9l2.6-7 1.7-1.7 4.7 4.7v2.8zm2.5-4V4.6c0-2-1.5-3.5-3.5-3.5h-.9c-.2 0-.4.1-.6.2-.2.1-.4.4-.5.6l-8.8 23.4c-.2.7.1 1.4.7 1.6.1 0 .3.1.4.1.1 0 .3.1.4.1h9.3c2 0 3.5-1.5 3.5-3.5v-4.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});