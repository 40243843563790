define("ember-svg-jar/inlined/alpha-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.705 7.734l1.21-5.476a.718.718 0 011.398.32l-1.035 4.734h2.156c.35 0 .64.291.64.626s-.29.64-.64.64h-3.015c-.539 0-.801-.32-.684-.844h-.03zm5.273.058l1.18-5.33c.102-.452.393-.685.845-.685h2.242c1.311 0 2.069.86 2.069 1.893 0 1.035-.685 2.506-2.811 2.506h-1.719l-.422 1.922a.718.718 0 01-1.399-.32l.015.014zm4.821-3.976c0-.451-.335-.786-.86-.786h-1.456l-.408 1.878h1.5c.772 0 1.224-.48 1.224-1.092zm6.365 3.976l.466-2.053h-3.219l-.524 2.374a.718.718 0 01-.7.553c-.45 0-.786-.408-.699-.874l1.21-5.534a.718.718 0 01.698-.554c.452 0 .787.393.7.874l-.408 1.879h3.204l.48-2.2a.718.718 0 01.7-.553c.437 0 .772.393.7.874l-1.224 5.535a.718.718 0 01-.7.553c-.451 0-.786-.408-.699-.874h.015zm8.084.874a.738.738 0 01-.729-.626l-.087-.655h-3.044l-.525.932a.748.748 0 01-.626.364c-.378 0-.699-.32-.699-.7 0-.13.044-.261.116-.363l3.19-5.273a1.254 1.254 0 011.078-.597 1.24 1.24 0 011.224 1.048l.844 5.04a.741.741 0 01-.728.86l-.014-.03zm-.976-2.534l-.408-3.088-1.748 3.088h2.156zM6.7 8.782c-.248 0-1.107-.057-1.384-.99-.014-.072-.058-.102-.102-.102-.043 0-.116.03-.204.117-.699.67-1.602 1.02-2.447 1.02C1.078 8.826 0 7.675 0 5.912 0 3.773 1.908 0 5.083 0 6.73 0 7.268 1.34 7.268 2.243c0 .117.03.19.073.19.16 0 .714-1.574.757-1.72.16-.45.452-.684.845-.684.393 0 .903.204.903.728 0 .583-1.777 3.496-2.884 4.894-.19.248-.247.32-.247.787 0 .262.014.713.335.713.262 0 .626-.29.815-.466a.734.734 0 01.452-.16c.495 0 .713.423.713.772 0 .787-1.587 1.471-2.33 1.471v.015zm-.801-5.36c.029-.189.029-.349.029-.509 0-.932-.452-1.37-1.063-1.37-1.602 0-3.132 2.827-3.132 4.327 0 .276.059 1.325.947 1.325.437 0 2.695-.364 3.219-3.787v.015z\"/>",
    "attrs": {
      "width": "36",
      "height": "11",
      "viewBox": "0 0 36 11",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});