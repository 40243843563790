define("ember-svg-jar/inlined/fb-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.5 10a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM13 7.25c0-.69.56-1.25 1.25-1.25h7.5a1.25 1.25 0 110 2.5h-7.5c-.69 0-1.25-.56-1.25-1.25z\"/><path fill-rule=\"evenodd\" d=\"M0 8a8 8 0 018-8h12a8 8 0 018 8v12a8 8 0 01-8 8H8a8 8 0 01-8-8V8zm8-5.5h12A5.5 5.5 0 0125.5 8v4h-23V8A5.5 5.5 0 018 2.5zm-5.5 12V20A5.5 5.5 0 008 25.5h12a5.5 5.5 0 005.5-5.5v-5.5h-23z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});