define("ember-svg-jar/inlined/lib-design", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.895 8.079a1.816 1.816 0 113.631 0 1.816 1.816 0 01-3.631 0zm6.579 0a1.816 1.816 0 113.631 0 1.816 1.816 0 01-3.631 0zM4.947 13.342a1.816 1.816 0 113.632 0 1.816 1.816 0 01-3.632 0zm14.474 0a1.816 1.816 0 113.632 0 1.816 1.816 0 01-3.632 0z\"/><path fill-rule=\"evenodd\" d=\"M1 14C1 6.837 6.837 1 14 1c7.179 0 13 5.26 13 11.684 0 4.268-3.469 7.737-7.737 7.737h-2.329a.81.81 0 00-.816.816c0 .204.086.383.206.533l.004.005a3.19 3.19 0 01.804 2.093A3.133 3.133 0 0114 27C6.837 27 1 21.163 1 14zM14 3.316C8.11 3.316 3.316 8.11 3.316 14S8.11 24.684 14 24.684a.81.81 0 00.816-.816.869.869 0 00-.222-.563l-.002-.002a3.109 3.109 0 01-.79-2.066 3.133 3.133 0 013.132-3.132h2.33a5.42 5.42 0 005.42-5.42c0-5.186-4.815-9.37-10.684-9.37z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});