define("ember-svg-jar/inlined/social-pinterest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M16.23 21.349c-1.793 0-3.48-.99-4.06-2.115 0 0-.964 3.908-1.17 4.662-.343 1.275-1.007 2.548-1.623 3.556-.877 1.147-1.89.296-2.011-.75-.04-1.164.002-2.543.29-3.798.32-1.376 2.138-9.244 2.138-9.244s-.531-1.083-.531-2.684c0-2.515 1.427-4.392 3.205-4.392 1.51 0 2.24 1.16 2.24 2.548 0 1.55-.968 3.87-1.466 6.019-.417 1.8.884 3.267 2.622 3.267 3.148 0 5.27-4.13 5.27-9.02 0-3.718-2.453-6.5-6.915-6.5-5.04 0-8.181 3.838-8.181 8.124 0 1.478.426 2.521 1.096 3.326.307.37.35.52.238.946-.079.314-.262 1.064-.337 1.361-.11.43-.452.583-.832.424-2.322-.969-3.403-3.565-3.403-6.48C2.8 5.778 6.781 0 14.675 0c6.344 0 10.52 4.687 10.52 9.718.002 6.66-3.622 11.63-8.965 11.63z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});