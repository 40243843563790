define("@latermedia/ember-later-analytics/utils/age-gender", ["exports", "@ember/utils", "@ember/array", "@latermedia/ember-later-analytics/utils/world-data", "@latermedia/ember-later-analytics/utils"], function (_exports, _utils, _array, _worldData, _utils2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatGenderData = _exports.formatAgeData = void 0;

  /**
   * @module Utils
   */

  /**
   * @class audience
   * @extends Utils
   */
  const GENDER_PIE_CHART_ORDER = ['F', 'M', 'U'];
  /**
   * Finds the Gender name for a provided gender code.
   * Makes use of the genderList util.
   *
   * @method getGenderNameFromCode
   * @param {String} code
   *
   * @returns {String} name of the corresponding gender if it exists.
   * Otherwise it falls back to the code provided
   */

  const getGenderNameFromCode = code => {
    const gender = (0, _array.A)(_worldData.genderList).findBy('code', code);
    return gender ? gender.name : code;
  };
  /**
   * Takes a raw gender data object and converts
   * it to be consumed by the report UI
   *
   * @method formatCountries
   * @param {Object} genderData
   *
   * @returns {Array.<Object>} formatted array of gender values
   */


  const formatGenderData = genderData => {
    if (typeof genderData !== 'object' || (0, _utils.isEmpty)(genderData) || (0, _utils.isEmpty)(Object.entries(genderData))) {
      return [];
    } // BE returns different keys for Instagram and TikTok


    let data = genderData;

    if ('Female' in genderData) {
      data = {
        F: genderData.Female,
        M: genderData.Male,
        U: genderData.Other
      };
    }

    const formattedGenderData = GENDER_PIE_CHART_ORDER.map(genderCode => {
      const decimalAsStr = data?.[genderCode] || '0';
      return {
        genderCode,
        title: getGenderNameFromCode(genderCode.toUpperCase()),
        value: decimalAsStr ? Number(decimalAsStr) : null,
        percentage: decimalAsStr ? (0, _utils2.preciseRound)(Number(decimalAsStr), 2) : null
      };
    });

    if (!(0, _utils.isPresent)(formattedGenderData)) {
      return [];
    }

    return GENDER_PIE_CHART_ORDER.map(genderCode => (0, _array.A)(formattedGenderData).findBy('genderCode', genderCode) ?? {
      genderCode: '',
      percentage: '',
      title: '',
      value: 0
    });
  };
  /**
   * Takes a raw age data object and converts
   * it to be consumed by the report UI
   *
   * @method formatCountries
   * @param {Object} ageData
   *
   * @returns {Array.<Object>} formatted array of age values
   */


  _exports.formatGenderData = formatGenderData;

  const formatAgeData = ageData => {
    if (typeof ageData !== 'object' || (0, _utils.isEmpty)(ageData) || (0, _utils.isEmpty)(Object.entries(ageData))) {
      return [];
    } // Note: filter out age ranges with value = 0


    return (0, _array.A)(Object.entries(ageData).map(_ref => {
      let [age, decimalAsStr] = _ref;
      return {
        title: age,
        value: Number(decimalAsStr),
        percentageOfWidth: (0, _utils2.preciseRound)(Number(decimalAsStr), 2)
      };
    }).filter(_ref2 => {
      let {
        value
      } = _ref2;
      return value;
    })).sortBy('title');
  };

  _exports.formatAgeData = formatAgeData;
});