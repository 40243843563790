define("@latermedia/ember-later-analytics/helpers/local-html-safe", ["exports", "@ember/component/helper", "@ember/template"], function (_exports, _helper, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)((args
  /*, hash*/
  ) => {
    const [text] = args;

    if (typeof text === 'string') {
      return (0, _template.htmlSafe)(text);
    }

    return text;
  });

  _exports.default = _default;
});