define("ember-svg-jar/inlined/lib-button-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M17.395 14.055l1.756-1.756a4.44 4.44 0 016.275 0 4.44 4.44 0 010 6.276l-1.755 1.755a1.127 1.127 0 11-1.595-1.594l1.756-1.756a2.19 2.19 0 000-3.086 2.19 2.19 0 00-3.087 0l-1.755 1.755a1.127 1.127 0 11-1.595-1.594zm.585 8.777l1.756-1.756a1.127 1.127 0 111.594 1.595l-1.755 1.755a4.44 4.44 0 01-6.276 0 4.44 4.44 0 010-6.275l1.756-1.756a1.127 1.127 0 111.594 1.595l-1.755 1.755a2.19 2.19 0 000 3.087 2.19 2.19 0 003.086 0zm-1.17-3.511l3.51-3.511a1.128 1.128 0 011.596 1.595l-3.511 3.51a1.128 1.128 0 01-1.595-1.594z\" clip-rule=\"evenodd\"/><path d=\"M4.715 5.036h16.63a.68.68 0 01.68.68v2.766a1.018 1.018 0 002.036 0V5.715c0-1.5-1.216-2.715-2.715-2.715H4.716A2.715 2.715 0 002 5.715v16.63c0 1.5 1.216 2.716 2.715 2.716h4.267a1.018 1.018 0 000-2.037H4.715a.679.679 0 01-.679-.678V5.716a.68.68 0 01.68-.68z\"/><path fill-rule=\"evenodd\" d=\"M23 8H3V6h20v2z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});