define("ember-svg-jar/inlined/starred", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M27.895 10.44a2.036 2.036 0 00-1.61-1.365l-7.292-1.118-3.161-6.788a2.02 2.02 0 00-3.663 0L9.007 7.955 1.716 9.073A2.038 2.038 0 00.573 12.5l5.343 5.502-1.237 7.633a2.022 2.022 0 002.975 2.108L14 24.214l6.347 3.53c.3.165.637.252.98.255a2.026 2.026 0 001.994-2.363l-1.236-7.633 5.343-5.502a2.036 2.036 0 00.467-2.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});