define("ember-svg-jar/inlined/average", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#323B43\" d=\"M1.167 0c.644 0 1.166.522 1.166 1.167v24.5h24.5a1.167 1.167 0 110 2.333H1.167A1.167 1.167 0 010 26.833V1.167C0 .522.522 0 1.167 0z\"/><path fill=\"#323B43\" fill-rule=\"evenodd\" d=\"M8.125 20.029v-.038l.005-.125a39.74 39.74 0 01.17-2.25 39.39 39.39 0 01.523-3.512c.07.014.144.021.219.021h.916a1.125 1.125 0 100-2.25H9.35c.502-1.79 1.186-3.468 2.1-4.707.966-1.31 2.114-2.043 3.551-2.043 1.437 0 2.585.733 3.55 2.043.915 1.24 1.6 2.918 2.1 4.707h-.608a1.125 1.125 0 100 2.25h.916c.075 0 .149-.007.22-.021.254 1.288.417 2.51.522 3.513a39.705 39.705 0 01.17 2.25l.004.124.001.03v.007a1.125 1.125 0 002.25-.056v-.015l-.002-.04a19.428 19.428 0 00-.032-.67 41.99 41.99 0 00-.153-1.864c-.16-1.533-.455-3.594-1-5.669-.54-2.058-1.348-4.216-2.576-5.882-1.247-1.69-3-2.957-5.362-2.957-2.363 0-4.115 1.267-5.362 2.957-1.228 1.666-2.036 3.824-2.576 5.882-.545 2.075-.84 4.136-1 5.67a41.994 41.994 0 00-.18 2.39l-.005.143-.001.04v.014a1.126 1.126 0 002.249.058zM7 20l-1.125-.029L7 20zm16 0l1.125-.028L23 20z\" clip-rule=\"evenodd\"/><path fill=\"#323B43\" d=\"M4 11.875a1.125 1.125 0 000 2.25h.458a1.125 1.125 0 000-2.25H4zm10.542 0a1.125 1.125 0 100 2.25h.916a1.125 1.125 0 100-2.25h-.916zm11 0a1.125 1.125 0 100 2.25H26a1.125 1.125 0 000-2.25h-.458z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});