define("@latermedia/ember-later-analytics/utils/is-mobile-web", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isMobileWeb = isMobileWeb;

  /**
   * Test whether client is running on mobile web
   *
   * @returns Client is running on mobile web
   */
  function isMobileWeb() {
    const mobileWebUserAgent = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry)/);
    return (0, _utils.isPresent)(mobileWebUserAgent);
  }

  var _default = isMobileWeb;
  _exports.default = _default;
});