define("ember-svg-jar/inlined/lib--button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M12.229 9.526L14.255 7.5a5.124 5.124 0 017.245 7.245l-2.026 2.027a1.302 1.302 0 01-1.841-1.841l2.026-2.027c.98-.98.98-2.584 0-3.563a2.528 2.528 0 00-3.563 0l-2.027 2.026a1.302 1.302 0 11-1.84-1.84zm.675 10.133l2.027-2.026a1.302 1.302 0 011.84 1.84L14.745 21.5A5.124 5.124 0 017.5 14.255l2.026-2.027a1.302 1.302 0 011.841 1.841l-2.026 2.027c-.98.98-.98 2.584 0 3.563.979.98 2.584.98 3.563 0zm-1.351-4.053l4.053-4.053a1.302 1.302 0 111.841 1.84l-4.053 4.054a1.302 1.302 0 01-1.841-1.84z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});