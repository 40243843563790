define("ember-svg-jar/inlined/lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22.4 12.6V8.4C22.4 3.8 18.6 0 14 0S5.6 3.8 5.6 8.4v4.2H4V28h20V12.6h-1.6zM7.8 8.4c0-3.4 2.8-6.2 6.2-6.2s6.2 2.7 6.2 6.2v4.2H7.8V8.4z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});