define("@latermedia/ember-later-analytics/components/best-performing-media/image", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <figure class="o--mediaContainer" ...attributes>
    {{#if @media.isVideo}}
      <span class="o--media__label--type">
        {{svg-jar "video" class="o--icon--md o--icon--white"}}
      </span>
    {{/if}}
    {{#if @media.isCarousel}}
      <span class="o--media__label--type">
        {{svg-jar "gallery" class="o--icon--md o--icon--white"}}
      </span>
    {{/if}}
    <img
      src={{@media.url}}
      onerror={{fn @handleError @media}}
      class="o--media"
    >
    <b class="o--mediaOverlay"></b>
  </figure>
  */
  {
    "id": "pJSInMfm",
    "block": "[[[11,\"figure\"],[24,0,\"o--mediaContainer\"],[17,1],[12],[1,\"\\n\"],[41,[30,2,[\"isVideo\"]],[[[1,\"    \"],[10,1],[14,0,\"o--media__label--type\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"video\"],[[\"class\"],[\"o--icon--md o--icon--white\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,2,[\"isCarousel\"]],[[[1,\"    \"],[10,1],[14,0,\"o--media__label--type\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"gallery\"],[[\"class\"],[\"o--icon--md o--icon--white\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"img\"],[15,\"src\",[30,2,[\"url\"]]],[15,\"onerror\",[28,[37,2],[[30,3],[30,2]],null]],[14,0,\"o--media\"],[12],[13],[1,\"\\n  \"],[10,\"b\"],[14,0,\"o--mediaOverlay\"],[12],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@media\",\"@handleError\"],false,[\"if\",\"svg-jar\",\"fn\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/best-performing-media/image.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});