define("ember-svg-jar/inlined/alt-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.33 17.987c.526 0 .821-.269 1.01-.924l.263-.827h2.653l.263.86c.172.633.467.89 1.037.89.574 0 .988-.391.988-.945 0-.204-.038-.386-.129-.639L9.487 11.15C9.19 10.344 8.719 10 7.907 10c-.767 0-1.245.36-1.53 1.15l-1.912 5.252c-.086.237-.14.478-.14.645 0 .585.382.94 1.005.94zm1.665-3.239l.88-2.986h.043l.908 2.986H6.995z\" fill=\"currentColor\"/><path d=\"M13.483 17.869h3.41c.532 0 .876-.29.876-.795 0-.505-.35-.795-.875-.795h-2.428v-5.253c0-.655-.36-1.026-.983-1.026s-.988.37-.988 1.026v5.811c0 .656.365 1.032.988 1.032zM21.438 16.96c0 .656-.36 1.027-.983 1.027s-.983-.37-.983-1.026v-5.253h-1.36c-.526 0-.88-.29-.88-.795 0-.505.344-.795.88-.795h4.69c.532 0 .875.29.875.795 0 .505-.354.795-.875.795h-1.364v5.253z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1 5a4 4 0 014-4h18a4 4 0 014 4v18a4 4 0 01-4 4H5a4 4 0 01-4-4V5zm4-1.5h18A1.5 1.5 0 0124.5 5v18a1.5 1.5 0 01-1.5 1.5H5A1.5 1.5 0 013.5 23V5A1.5 1.5 0 015 3.5z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});