define("@latermedia/ember-later-analytics/components/section", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class={{if @isReport "cCH--section" "u--p__b__md"}} ...attributes>
    {{#if @title}}
      <h2 data-test-id="title" class={{concat @headerClassName (if @isReport " cCH--section__title" " tAN--header")}}>
        {{@title}}
      </h2>
    {{/if}}
    
    {{#if @description}}
      <p class={{if @isReport "u--m__b__lg" "tAN--subtitle"}} data-test-id="description">
        
        {{local-html-safe @description}}
        
        {{#if @tooltip}}
          <Shared::Ui::IconTooltip @tooltip={{@tooltip}} />
        {{/if}}
        
        <br>
        
        {{#if @subDescription}}
          {{@subDescription}}
        {{/if}}
      </p>
    {{/if}}
    
    {{yield}}
  </section>
  */
  {
    "id": "oGK+mlGR",
    "block": "[[[11,\"section\"],[16,0,[52,[30,1],\"cCH--section\",\"u--p__b__md\"]],[17,2],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,\"h2\"],[14,\"data-test-id\",\"title\"],[15,0,[28,[37,1],[[30,4],[52,[30,1],\" cCH--section__title\",\" tAN--header\"]],null]],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \\n\"],[41,[30,5],[[[1,\"    \"],[10,2],[15,0,[52,[30,1],\"u--m__b__lg\",\"tAN--subtitle\"]],[14,\"data-test-id\",\"description\"],[12],[1,\"\\n      \\n      \"],[1,[28,[35,2],[[30,5]],null]],[1,\"\\n      \\n\"],[41,[30,6],[[[1,\"        \"],[8,[39,3],null,[[\"@tooltip\"],[[30,6]]],null],[1,\"\\n\"]],[]],null],[1,\"      \\n      \"],[10,\"br\"],[12],[13],[1,\"\\n      \\n\"],[41,[30,7],[[[1,\"        \"],[1,[30,7]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \\n  \"],[18,8,null],[1,\"\\n\"],[13]],[\"@isReport\",\"&attrs\",\"@title\",\"@headerClassName\",\"@description\",\"@tooltip\",\"@subDescription\",\"&default\"],false,[\"if\",\"concat\",\"local-html-safe\",\"shared/ui/icon-tooltip\",\"yield\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/section.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});