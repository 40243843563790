define("ember-svg-jar/inlined/hashtag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M12.693 2.577A1.42 1.42 0 0011.547.948a1.38 1.38 0 00-1.61 1.133L9.022 7.53H4.1a1.4 1.4 0 000 2.8h4.452l-1.146 6.83H2.1a1.4 1.4 0 100 2.8h4.835l-.9 5.36a1.42 1.42 0 001.147 1.628 1.38 1.38 0 001.61-1.132l.982-5.857h5.828l-.9 5.36a1.42 1.42 0 001.146 1.63 1.38 1.38 0 001.61-1.133l.983-5.857H23.3a1.4 1.4 0 100-2.8h-4.389l1.147-6.83H25.3a1.4 1.4 0 000-2.8h-4.772l.832-4.952A1.42 1.42 0 0020.214.948a1.38 1.38 0 00-1.61 1.133l-.915 5.449h-5.828l.832-4.953zm3.38 14.582l1.146-6.83h-5.828l-1.146 6.83h5.827z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});