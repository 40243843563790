define("ember-svg-jar/inlined/hide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M24.294 2.688c.459-.301 1.003-.24 1.426.183.495.495.495 1.155 0 1.65L23 7.241c3.333 3.017 5 6.767 5 6.767s-4.667 10.5-14 10.5c-2.476 0-4.623-.739-6.442-1.824l-2.462 2.461c-.495.495-1.155.495-1.65 0-.495-.495-.495-1.155 0-1.65l.21-.208c.076-.05.149-.11.219-.18zm-2.957 6.217l-2.253 2.252c.477.844.75 1.818.75 2.851 0 3.208-2.626 5.833-5.834 5.833a5.783 5.783 0 01-2.851-.749l-1.854 1.854c1.385.745 2.958 1.229 4.705 1.229 6.183 0 10.033-5.834 11.317-8.167-.66-1.199-1.997-3.322-3.98-5.103zM14 3.508c1.879 0 3.569.426 5.07 1.105L17.272 6.41A9.705 9.705 0 0014 5.841c-6.183 0-10.033 5.834-11.317 8.167.533.969 1.528 2.54 2.944 4.048L3.942 19.74C1.314 16.964 0 14.008 0 14.008l.012-.027C.282 13.39 4.945 3.508 14 3.508zm3.325 9.41l-4.415 4.415c.343.114.71.175 1.09.175a3.51 3.51 0 003.5-3.5c0-.38-.061-.747-.175-1.09zM14 8.175c.464 0 .915.055 1.349.158l-2.31 2.31a3.526 3.526 0 00-2.404 2.403l-2.31 2.31a5.796 5.796 0 01-.158-1.348c0-3.208 2.625-5.833 5.833-5.833z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});