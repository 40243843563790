define("ember-svg-jar/inlined/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M22.71.069c-2-.3-3.902.4-5.302 1.8C17.008 2.27 1 18.177 1 18.177l-.7.7c-.1.1-.2.2-.2.3 0 .1-.1.3-.1.4v7.403c0 .6.4 1 1 1h7.404c.3 0 .5-.1.7-.3l.7-.7 16.307-16.307c2.502-2.4 2.502-6.303.1-8.804-1-1-2.2-1.6-3.501-1.8zm-.3 2c.9.1 1.7.5 2.4 1.201 1.402 1.4 1.602 3.602.601 5.202L19.61 2.67c.8-.5 1.8-.7 2.8-.6zM2 26.08v-5.502l2.702 2.7 2.701 2.702H2.001v.1zm7.204-1.2l-2.301-2.302L21.81 7.672l2.3 2.3L9.205 24.88zm-3.703-3.772L3.2 18.807 18.107 3.9l2.3 2.301L5.502 21.108z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});