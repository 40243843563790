define("ember-svg-jar/inlined/type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M23.33 3.21H4.67a1.46 1.46 0 00-1.46 1.46v3.5a1.46 1.46 0 002.91 0v-2h6.42v15.71h-2a1.46 1.46 0 100 2.91h7a1.46 1.46 0 100-2.91h-2V6.12h6.42v2.05a1.46 1.46 0 002.91 0v-3.5a1.46 1.46 0 00-1.54-1.46z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});