define("ember-svg-jar/inlined/first-comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M25.292 0H2.708A2.722 2.722 0 000 2.726v18.62c0 1.48 1.193 2.727 2.708 2.727h6.794l3.81 3.742a.655.655 0 00.459.185h.458a.699.699 0 00.46-.185l3.81-3.742h6.793A2.722 2.722 0 0028 21.346V2.726C28 1.248 26.807 0 25.292 0zM3.874 21.532a1.213 1.213 0 01-1.207-1.218V3.618c0-.672.541-1.218 1.207-1.218h20.52c.666 0 1.207.546 1.207 1.218v16.738c0 .672-.541 1.176-1.207 1.176H17.86c-.166 0-.333.126-.416.21l-3.413 3.36-3.29-3.36c-.124-.126-.29-.21-.415-.21H3.874zM19.303 8.987h-.849l.319-1.355c.106-.573-.265-1.094-.849-1.25-.583-.105-1.113.26-1.273.833l-.318 1.772h-3.128l.265-1.355c.106-.573-.266-1.094-.849-1.25-.583-.105-1.113.26-1.273.833l-.318 1.772H9.758c-.584 0-1.061.469-1.061 1.042 0 .573.477 1.042 1.06 1.042h.85l-.637 3.127H8.697c-.583 0-1.06.469-1.06 1.042 0 .573.477 1.042 1.06 1.042h.848l-.318 1.355c-.106.573.265 1.094.849 1.25h.212c.477 0 .954-.364 1.06-.833l.372-1.772h3.128l-.318 1.355c-.106.573.266 1.094.849 1.25h.212c.477 0 .955-.364 1.06-.833l.372-1.772h1.22c.583 0 1.06-.469 1.06-1.042 0-.573-.477-1.042-1.06-1.042h-.85l.637-3.127h1.273c.583 0 1.06-.469 1.06-1.042 0-.573-.477-1.042-1.06-1.042zm-4.03 5.21h-3.129l.636-3.126h3.13l-.637 3.127z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});