define("@latermedia/ember-later-analytics/utils/camel-to-snake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Utils
   */

  /**
   * @class camelToSnake
   * @extends Utils
   */

  /** Returns a snake cased version of the given camel cased string
   *
   * @method camelToSnake
   * @param {String} str Camel cased string
   *
   * @returns {String} Snake cased version of str
   * @protected
   */
  var _default = str => str.replace(/([A-Z])/g, '_$1').toLowerCase().replace(/^_/, '');

  _exports.default = _default;
});