define("ember-svg-jar/inlined/stitch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 .25A4.25 4.25 0 003.75 4.5V7a1.25 1.25 0 102.5 0V4.5c0-.966.784-1.75 1.75-1.75h1.5a1.25 1.25 0 100-2.5H8zM6.25 21a1.25 1.25 0 10-2.5 0v2.5A4.25 4.25 0 008 27.75h1.5a1.25 1.25 0 100-2.5H8a1.75 1.75 0 01-1.75-1.75V21zM5 10.25c.69 0 1.25.56 1.25 1.25v5a1.25 1.25 0 11-2.5 0v-5c0-.69.56-1.25 1.25-1.25zm9-10a1.25 1.25 0 100 2.5h6c.966 0 1.75.784 1.75 1.75v19A1.75 1.75 0 0120 25.25h-6a1.25 1.25 0 100 2.5h6a4.25 4.25 0 004.25-4.25v-19A4.25 4.25 0 0020 .25h-6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});