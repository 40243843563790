define("ember-svg-jar/inlined/caret-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M19.364 13.707l-4.657 4.657a1 1 0 01-1.414 0l-4.657-4.657A1 1 0 019.343 12h9.314a1 1 0 01.707 1.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});