define("ember-svg-jar/inlined/external", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M11.454 11.018h12.219L17.69 5.164a1.23 1.23 0 010-1.782 1.23 1.23 0 011.782 0l8.145 7.89c.51.51.51 1.274 0 1.783l-8.145 7.89a1.23 1.23 0 01-1.782 0 1.23 1.23 0 010-1.781l5.982-5.855H11.455c-4.964 0-8.91 3.946-8.91 8.782v1.273c0 .636-.509 1.272-1.272 1.272-.764 0-1.273-.509-1.273-1.272V22.09c0-5.982 5.09-11.073 11.454-11.073z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});