define("@latermedia/ember-later-analytics/components/ui/pie-chart", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/array"], function (_exports, _component, _templateFactory, _component2, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEGREE_OFFSET_PERCENT = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cCH--pieChart" data-test-id="pie-chart">
    <svg width="100" height="100" viewBox="0 0 32 32">
      <circle
        style={{local-html-safe (concat "transform: rotate(" this.circleOffsetRotation "deg); transform-origin: center;")}}
        r="16"
        cx="16"
        cy="16"
        fill={{this.primaryFill}}
        stroke={{this.primaryStroke}}
        stroke-width="32"
        stroke-dasharray={{t "formatters.stroke_dasharray_value" percentage=this.femalePercentage}}
        data-test-id="female-circle"
      ></circle>
      <circle
        r="16"
        cx="16"
        cy="16"
        fill="transparent"
        stroke={{this.secondaryStroke}}
        stroke-width="32"
        stroke-dasharray={{t "formatters.stroke_dasharray_value" percentage=this.undisclosedPercentage}}
        data-test-id="undisclosed-circle"
      ></circle>
    </svg>
    <div class="cCH--pieChart__legend" data-test-id="legend">
      {{#each this.formattedValues as |value|}}
        <div class={{concat "cCH--pieChart__legendValue cCH--pieChart__legendValue--" value.genderCodeLowercase}}>
          <span class="cCH--pieChart__legendIndicator"></span>
          {{concat value.title " " (t "formatters.percentage" percentage=value.percentage)}}
        </div>
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "q6FGVXhF",
    "block": "[[[10,0],[14,0,\"cCH--pieChart\"],[14,\"data-test-id\",\"pie-chart\"],[12],[1,\"\\n  \"],[10,\"svg\"],[14,\"width\",\"100\"],[14,\"height\",\"100\"],[14,\"viewBox\",\"0 0 32 32\"],[12],[1,\"\\n    \"],[10,\"circle\"],[15,5,[28,[37,0],[[28,[37,1],[\"transform: rotate(\",[30,0,[\"circleOffsetRotation\"]],\"deg); transform-origin: center;\"],null]],null]],[14,\"r\",\"16\"],[14,\"cx\",\"16\"],[14,\"cy\",\"16\"],[15,\"fill\",[30,0,[\"primaryFill\"]]],[15,\"stroke\",[30,0,[\"primaryStroke\"]]],[14,\"stroke-width\",\"32\"],[15,\"stroke-dasharray\",[28,[37,2],[\"formatters.stroke_dasharray_value\"],[[\"percentage\"],[[30,0,[\"femalePercentage\"]]]]]],[14,\"data-test-id\",\"female-circle\"],[12],[13],[1,\"\\n    \"],[10,\"circle\"],[14,\"r\",\"16\"],[14,\"cx\",\"16\"],[14,\"cy\",\"16\"],[14,\"fill\",\"transparent\"],[15,\"stroke\",[30,0,[\"secondaryStroke\"]]],[14,\"stroke-width\",\"32\"],[15,\"stroke-dasharray\",[28,[37,2],[\"formatters.stroke_dasharray_value\"],[[\"percentage\"],[[30,0,[\"undisclosedPercentage\"]]]]]],[14,\"data-test-id\",\"undisclosed-circle\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"cCH--pieChart__legend\"],[14,\"data-test-id\",\"legend\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"formattedValues\"]]],null]],null],null,[[[1,\"      \"],[10,0],[15,0,[28,[37,1],[\"cCH--pieChart__legendValue cCH--pieChart__legendValue--\",[30,1,[\"genderCodeLowercase\"]]],null]],[12],[1,\"\\n        \"],[10,1],[14,0,\"cCH--pieChart__legendIndicator\"],[12],[13],[1,\"\\n        \"],[1,[28,[35,1],[[30,1,[\"title\"]],\" \",[28,[37,2],[\"formatters.percentage\"],[[\"percentage\"],[[30,1,[\"percentage\"]]]]]],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"value\"],false,[\"local-html-safe\",\"concat\",\"t\",\"each\",\"-track-array\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/pie-chart.hbs",
    "isStrictMode": false
  });

  // Note: this is a hack to compute the 3rd pie item's offset
  const DEGREE_OFFSET_PERCENT = 3.6;
  _exports.DEGREE_OFFSET_PERCENT = DEGREE_OFFSET_PERCENT;

  class PieChartComponent extends _component2.default {
    get primaryFill() {
      return this.args.primaryFill || "#284DC1";
    }

    get primaryStroke() {
      return this.args.primaryStroke || "#6788F1";
    }

    get secondaryStroke() {
      return this.args.secondaryStroke || "#BAA6FF";
    }

    get femalePercentage() {
      return this.args.values?.findBy('genderCode', 'F')?.percentage;
    }

    get undisclosedPercentage() {
      const undisclosedPieItem = this.args.values?.findBy('genderCode', 'U');
      const undisclosedPercentage = undisclosedPieItem?.value; // Note: Less than 1% is not visible in the pie chart

      return undisclosedPercentage && undisclosedPercentage < 1 ? '1' : undisclosedPieItem?.percentage;
    }

    get circleOffsetRotation() {
      return Number(this.undisclosedPercentage) * DEGREE_OFFSET_PERCENT;
    }

    get formattedValues() {
      const {
        values
      } = this.args;
      return (0, _array.A)(values?.map(value => ({ ...value,
        genderCodeLowercase: value.genderCode.toLowerCase()
      })));
    }

  }

  _exports.default = PieChartComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PieChartComponent);
});