define("@latermedia/ember-later-analytics/utils/object-methods", ["exports", "@latermedia/ember-later-analytics/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.objectMap = objectMap;
  _exports.objectSnakeToCamel = objectSnakeToCamel;

  function objectMap(obj, template, callback) {
    const result = { ...template
    };
    return Object.assign(result, Object.fromEntries(Object.entries(obj || {}).map(callback)));
  }

  function objectSnakeToCamel(obj, template) {
    return objectMap(obj, template, _ref => {
      let [snakeCaseKey, value] = _ref;
      return [(0, _utils.snakeToCamel)(snakeCaseKey), value];
    });
  }
});