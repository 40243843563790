/* eslint-disable no-undef */
self.deprecationWorkflow = self.deprecationWorkflow || {};
self.deprecationWorkflow.config = {
  workflow: [
    { handler: 'throw', matchId: 'argument-less-helper-paren-less-invocation' },
    { handler: 'throw', matchId: 'autotracking.mutation-after-consumption' },
    { handler: 'throw', matchId: 'computed-property.override' },
    { handler: 'throw', matchId: 'deprecated-run-loop-and-computed-dot-access' },
    { handler: 'throw', matchId: 'deprecate-router-events' },
    { handler: 'throw', matchId: 'ds.serializer.rest.queryRecord-array-response' },
    { handler: 'throw', matchId: 'ember-data:-legacy-test-registrations' },
    { handler: 'silence', matchId: 'ember-data:legacy-test-helper-support' },
    { handler: 'throw', matchId: 'ember-data:evented-api-usage' },
    { handler: 'throw', matchId: 'ember-data:ember-data:model.toJSON' },
    { handler: 'throw', matchId: 'ember-global' },
    { handler: 'throw', matchId: 'ember.component.reopen' },
    { handler: 'throw', matchId: 'ember.built-in-components.import' },
    { handler: 'throw', matchId: 'ember.built-in-components.legacy-arguments' },
    { handler: 'throw', matchId: 'ember.built-in-components.legacy-attribute-arguments' },
    { handler: 'throw', matchId: 'ember.link-to.tag-name' },
    { handler: 'silence', matchId: 'ember-engines.deprecation-router-service-from-host' },
    { handler: 'throw', matchId: 'ember-engines.deprecation-camelized-engine-names' },
    { handler: 'throw', matchId: 'ember-metal.get-with-default' },
    { handler: 'silence', matchId: 'ember-modifier.function-based-options' },
    { handler: 'silence', matchId: 'ember-modifier.no-args-property' },
    { handler: 'silence', matchId: 'ember-modifier.no-element-property' },
    { handler: 'silence', matchId: 'ember-modifier.use-destroyables' },
    { handler: 'silence', matchId: 'ember-modifier.use-modify' },
    { handler: 'throw', matchId: 'ember-source.deprecation-without-for' },
    { handler: 'throw', matchId: 'ember-source.deprecation-without-since' },
    { handler: 'throw', matchId: 'ember-simple-auth.events.session-service' },
    { handler: 'throw', matchId: 'ember-simple-auth.mixins.application-route-mixin' },
    { handler: 'throw', matchId: 'ember-simple-auth.initializer.setup-session-restoration' },
    { handler: 'throw', matchId: 'implicit-injections' },
    { handler: 'throw', matchId: 'manager-capabilities.modifiers-3-13' },
    { handler: 'throw', matchId: 'old-deprecate-method-paths' },
    { handler: 'throw', matchId: 'routing.transition-methods' },
    { handler: 'throw', matchId: 'template-compiler.registerPlugin' },
    { handler: 'throw', matchId: 'this-property-fallback' },
    { handler: 'throw', matchId: 'route-disconnect-outlet' },
    { handler: 'throw', matchId: 'route-render-template' }
  ]
};

// self.deprecationWorkflow.config = {
//   throwOnUnhandled: true
// };
