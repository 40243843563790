define("ember-get-config/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global require */
  let configModulePath = `${"later"}/config/environment`;

  var _default = require(configModulePath).default;

  _exports.default = _default;
});