define("@latermedia/ember-later-analytics/utils/followers-online-hourly", ["exports", "@ember/utils", "@latermedia/ember-later-analytics/utils/constants", "@latermedia/ember-later-analytics/utils/time-methods", "@latermedia/ember-later-analytics/utils"], function (_exports, _utils, _constants, _timeMethods, _utils2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Utils
   */

  /**
   * @class followersOnlineHourly
   * @extends Utils
   */

  /**
   * Takes a raw followers online object and converts
   * it to be consumed by the report UI
   *
   * @method formatFollowersOnlineHourlyData
   * @param {Array.<Object>} followersOnlineHourlyHourlyData
   *
   * @returns {Array.<Object>} formatted array of follower online objects
   */
  const formatFollowersOnlineHourlyData = (followersOnlineHourlyData, timezone) => {
    if (typeof followersOnlineHourlyData !== 'object' || (0, _utils.isEmpty)(followersOnlineHourlyData)) {
      return [];
    }

    const binTitles = _constants.HOURS_OF_THE_DAY.map((value, index) => {
      const BIN_WIDTH_IN_HOURS = 3;

      if (index % BIN_WIDTH_IN_HOURS === 0) {
        return value;
      }

      return null;
    });

    return followersOnlineHourlyData.map((dayValues, dayIndex) => {
      const currentWeekday = (0, _timeMethods.unixToWeekday)(dayValues.day_start, timezone);
      const currentWeekdayShort = (0, _timeMethods.unixToWeekday)(dayValues.day_start, timezone, 'short');
      const currentDate = (0, _timeMethods.unixToDate)(dayValues.day_start, timezone);
      const windowValuesAsNumbers = dayValues.windows.map(_ref => {
        let {
          online_count
        } = _ref;
        return Number(online_count);
      });
      const dayTotalCounts = windowValuesAsNumbers.reduce((currentTotalCounts, currentCounts) => currentTotalCounts + currentCounts, 0);
      const topWindowValue = Math.max(...windowValuesAsNumbers);
      const topWindowIndex = windowValuesAsNumbers.indexOf(topWindowValue);
      const rawValues = dayValues.windows.map((windowValues, windowIndex) => ({
        rank: null,
        rawCount: Number(windowValues.online_count),
        count: (0, _utils2.formatNumberWithSuffix)(Number(windowValues.online_count)),
        percentageOfWeek: dayTotalCounts === 0 ? 0 : Math.floor(Number(windowValues.online_count) / dayTotalCounts * 100),
        percentageOfHeight: Math.floor(Number(windowValues.online_count) / topWindowValue * 100),
        isCountVisible: windowIndex === topWindowIndex,
        visibleTitle: binTitles[windowIndex],
        title: _constants.HOURS_OF_THE_DAY[windowIndex]
      }));
      const valuesSortedByCount = [...rawValues].sort((prev, curr) => curr.rawCount - prev.rawCount);
      const valuesWithRank = rawValues.map(windowValues => {
        const indexOfSortedValues = valuesSortedByCount.findIndex(_ref2 => {
          let {
            title
          } = _ref2;
          return title === windowValues.title;
        });
        return { ...windowValues,
          rank: indexOfSortedValues + 1
        };
      });
      return {
        canIncrement: dayIndex !== _constants.DAYS_OF_THE_WEEK.length - 1,
        canDecrement: dayIndex !== 0,
        day: currentWeekday,
        formattedDay: `${currentWeekdayShort} ${currentDate}`,
        isMostActive: dayValues.is_best_day,
        values: valuesWithRank
      };
    });
  };

  var _default = formatFollowersOnlineHourlyData;
  _exports.default = _default;
});