define("ember-svg-jar/inlined/campaign-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 17.571v-7.235c.384.156.804.242 1.244.242H2.33v6.02L19 19.606V6.394l-4.482.809a3.306 3.306 0 00-.891-2.207L19 4.026V2.165a1.165 1.165 0 012.33 0v21.67a1.165 1.165 0 01-2.33 0v-1.862l-6-1.082V23.5a3.5 3.5 0 11-7 0v-3.872l-3.67-.663v.87a1.165 1.165 0 11-2.33 0V17.57zm12.401-9.986c-.143.53-.626.919-1.201.919H7.467v3.733a1.244 1.244 0 11-2.49 0V8.504H1.245a1.244 1.244 0 010-2.49h3.734V2.282a1.244 1.244 0 112.489 0v3.734H11.2a1.244 1.244 0 011.201 1.57zM8.33 20.048l2.34.422v3.03a1.17 1.17 0 11-2.34 0v-3.452zM27.48 6.969a1.165 1.165 0 10-1.292-1.938l-3 2a1.165 1.165 0 101.292 1.938l3-2zm-3.646 4.866a1.165 1.165 0 000 2.33h3a1.165 1.165 0 000-2.33h-3zm-.97 5.519a1.165 1.165 0 011.616-.323l3 2a1.165 1.165 0 01-1.292 1.938l-3-2a1.165 1.165 0 01-.323-1.615z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});