define("ember-svg-jar/inlined/lib-design-theme", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M13.618 20.388a4.223 4.223 0 00.7-.688l.207.184a4.593 4.593 0 01-.738.732 28.613 28.613 0 01-.169-.228zm-1.642.769l-.151.03a4.042 4.042 0 01-2.412-.319 4.242 4.242 0 01-1.833-1.653 4.47 4.47 0 01-.642-2.424l.117-4.745a.904.904 0 01.61-.84.87.87 0 01.987.327c.016.02.046.053.09.098.09.09.236.22.444.354.41.267 1.071.563 2.036.592.829.024 1.63.303 2.306.8a4.331 4.331 0 011.486 1.99 4.495 4.495 0 01.135 2.678 27.106 27.106 0 011.174 1.066c.56.54 1.151 1.16 1.698 1.83 1.132 1.39 2.226 3.18 2.181 5.006-.012.491-.408.898-.907.883-1.765-.051-3.43-1.292-4.7-2.541a21.117 21.117 0 01-1.703-1.91 27.733 27.733 0 01-.916-1.222zm2.938.861c.285.325.586.65.9.96.816.8 1.646 1.43 2.427 1.767-.28-.84-.844-1.744-1.574-2.639a17.45 17.45 0 00-.856-.97c-.27.324-.57.62-.896.882zm-2.865-2.782c.265-.116.51-.28.721-.49a2.507 2.507 0 00.554-.824 2.628 2.628 0 00.038-1.912 2.516 2.516 0 00-.863-1.157 2.347 2.347 0 00-1.324-.46c-.992-.03-1.786-.27-2.396-.569l-.074 3.016c-.013.5.118.993.374 1.416.256.422.625.755 1.06.957a2.317 2.317 0 001.813.063.865.865 0 01.097-.04z\" clip-rule=\"evenodd\"/><path d=\"M9.18 9.518c.813.448 1.855.178 2.328-.604.472-.782.196-1.78-.616-2.227-.813-.449-1.855-.178-2.327.604-.472.781-.197 1.779.616 2.227zm5.148-1.044c.812.448 1.854.177 2.326-.604.473-.782.197-1.78-.616-2.228-.812-.448-1.854-.177-2.326.604-.473.782-.197 1.78.616 2.228zm4.321 2.697c.812.448 1.854.178 2.327-.604.472-.782.196-1.779-.616-2.227-.813-.448-1.855-.178-2.327.604-.473.782-.197 1.779.616 2.227zm.661 5.399c.813.447 1.855.177 2.327-.605s.197-1.779-.616-2.227c-.813-.448-1.854-.178-2.327.604-.472.782-.196 1.779.616 2.227z\"/><path d=\"M20.575 21.29C22.689 19.456 24 16.822 24 13.941 24 8.536 19.386 4 13.5 4S3 8.536 3 13.942c0 4.894 3.78 9.075 8.864 9.823l1.702 2.12H13.5C6.596 25.884 1 20.537 1 13.941 1 7.347 6.596 2 13.5 2S26 7.347 26 13.942c0 3.571-1.64 6.776-4.24 8.964l-1.186-1.617z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});