define("@latermedia/ember-later-analytics/utils/state-types/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Utils
   */

  /**
   * @class DataUtil
   * @extends Utils
   */

  /**
   * Data State
   *
   * @property DATA
   * @returns {string}
   */
  const DATA = 'data';
  var _default = DATA;
  _exports.default = _default;
});