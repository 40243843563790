define("ember-svg-jar/inlined/arrow-right-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M14 0C6.236 0 0 6.236 0 14s6.236 14 14 14 14-6.236 14-14S21.764 0 14 0zm0 25.454A11.407 11.407 0 012.545 14C2.545 7.636 7.636 2.545 14 2.545S25.454 7.636 25.454 14 20.364 25.454 14 25.454zm7.175-11.009c-.025.043-.049.078-.073.121-.042.085-3.388 4.922-3.388 4.922a1.458 1.458 0 01-1.827.421 1.056 1.056 0 01-.496-1.573l2.106-3.125H7.95a1.21 1.21 0 010-2.42h9.546L15.39 9.667a1.057 1.057 0 01.496-1.572c.631-.32 1.4-.143 1.827.42 0 0 3.316 4.801 3.388 4.918.08.138.125.293.133.452.01.03.014.045.012.06 0 .019.012.036.012.055 0 .02-.012.037-.012.056a.059.059 0 01-.012.042 1.35 1.35 0 01-.06.347z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});