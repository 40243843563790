define("@latermedia/ember-later-analytics/utils/snake-to-camel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Utils
   */

  /**
   * @class snakeToCamel
   * @extends Utils
   */

  /** Returns a camel cased version of the given snake cased string
   *
   * @method snakeToCamel
   * @param {String} str Snake cased string
   *
   * @returns {String} Camel cased version of str
   * @protected
   */
  var _default = str => {
    if (!str.includes('_')) {
      return str;
    }

    return str.toLowerCase().replace(/([_][a-z])/g, char => char.toUpperCase().replace('_', ''));
  };

  _exports.default = _default;
});