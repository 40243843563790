define("@latermedia/ember-later-analytics/components/ui/media-kit/tiktok-post-summary", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class="cPR--stats">
    <li class="cPR--stats__li">
      <p>{{t "media_kit.post.avg_likes" locale="en-us"}}</p>
      <span class="cPR--stats__count">{{rounded-value @tiles.averageLikes.main.value}}</span>
    </li>
    <li class="cPR--stats__li">
      <p>{{t "media_kit.post.avg_comments" locale="en-us"}}</p>
      <span class="cPR--stats__count">{{rounded-value @tiles.averageComments.main.value}}</span>
    </li>
    <li class="cPR--stats__li">
      <p>{{t "media_kit.post.avg_shares" locale="en-us"}}</p>
      <span class="cPR--stats__count">{{rounded-value @tiles.averageShares.main.value}}</span>
    </li>
  </ul>
  */
  {
    "id": "qAsE7F3e",
    "block": "[[[10,\"ul\"],[14,0,\"cPR--stats\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"cPR--stats__li\"],[12],[1,\"\\n    \"],[10,2],[12],[1,[28,[35,0],[\"media_kit.post.avg_likes\"],[[\"locale\"],[\"en-us\"]]]],[13],[1,\"\\n    \"],[10,1],[14,0,\"cPR--stats__count\"],[12],[1,[28,[35,1],[[30,1,[\"averageLikes\",\"main\",\"value\"]]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"cPR--stats__li\"],[12],[1,\"\\n    \"],[10,2],[12],[1,[28,[35,0],[\"media_kit.post.avg_comments\"],[[\"locale\"],[\"en-us\"]]]],[13],[1,\"\\n    \"],[10,1],[14,0,\"cPR--stats__count\"],[12],[1,[28,[35,1],[[30,1,[\"averageComments\",\"main\",\"value\"]]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"cPR--stats__li\"],[12],[1,\"\\n    \"],[10,2],[12],[1,[28,[35,0],[\"media_kit.post.avg_shares\"],[[\"locale\"],[\"en-us\"]]]],[13],[1,\"\\n    \"],[10,1],[14,0,\"cPR--stats__count\"],[12],[1,[28,[35,1],[[30,1,[\"averageShares\",\"main\",\"value\"]]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@tiles\"],false,[\"t\",\"rounded-value\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/media-kit/tiktok-post-summary.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});