define("@latermedia/ember-later-analytics/utils/precise-round", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Utils
   */

  /**
   * @class PreciseRoundUtil
   * @extends Utils
   */

  /**
   * Converts a given value into a formatted string
   * with the given number of significant digits.
   *
   * @method preciseRound
   * @param {Number|String} value Numerical value
   * @param {Number} significantDigits Number of significant digits to round to
   *
   * @return {String} Formatted string, at the precison level provided
   */
  const preciseRound = function () {
    let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    let significantDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    if (!(0, _utils.isPresent)(value) || typeof value === 'string' || typeof value === 'object') {
      return 'NaN';
    }

    const valueAsNumber = typeof value === 'string' ? Number.parseFloat(value) : value;

    if (valueAsNumber.toFixed().length > significantDigits) {
      return valueAsNumber.toFixed();
    }

    return valueAsNumber.toPrecision(significantDigits);
  };

  var _default = preciseRound;
  _exports.default = _default;
});