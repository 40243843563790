define("ember-svg-jar/inlined/media-kit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.8 0h-8.633c-1.984 0-3.5 1.516-3.5 3.5v16.333c0 1.984 1.516 3.5 3.5 3.5h11.666c1.984 0 3.5-1.516 3.5-3.5v-13.3l-.341-.358L23 4.2l-1.867-1.867L19.158.342 18.8 0zm.7 8.167H23v11.666c0 .17-.028.326-.08.465-.026.073-.06.142-.1.204-.197.312-.545.498-.987.498H10.167C9.467 21 9 20.533 9 19.833V3.5c0-.7.467-1.167 1.167-1.167h7v3.5A2.33 2.33 0 0019.5 8.167zm-14 17.5h14.933c-.466 1.4-1.75 2.333-3.266 2.333H5.5C3.516 28 2 26.484 2 24.5V8.167c0-1.517.933-2.8 2.333-3.267v19.6c0 .7.467 1.167 1.167 1.167zM21.35 5.833l-1.85-1.85v1.85h1.85zm-9.099 5.682l1.774-.657.656-1.773a1.393 1.393 0 011.32-.918c.594 0 1.111.36 1.318.918l.657 1.773 1.772.657c.559.206.919.724.919 1.318 0 .595-.36 1.113-.918 1.32l-1.774.656-.657 1.773A1.393 1.393 0 0116 17.5c-.595 0-1.112-.36-1.319-.918l-.656-1.773-1.773-.657a1.393 1.393 0 01-.919-1.319c0-.594.36-1.112.918-1.318zm3.75-1.04l-.414 1.114a1.402 1.402 0 01-.83.831l-1.116.414 1.116.413c.385.142.688.445.83.832L16 15.193l.413-1.115c.141-.384.444-.689.83-.831l1.116-.414-1.116-.413a1.402 1.402 0 01-.83-.832L16 10.473z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});