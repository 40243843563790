define("ember-svg-jar/inlined/media-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M18.991 24.732L17.223 26.5l-6.258-6.258a1 1 0 00-1.414 0L3.785 26.01l-1.767-1.768 7.534-7.534a1 1 0 011.414 0l8.025 8.025z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M25.818 14c-.709 0-1.182.473-1.182 1.182v8.273c0 .709-.472 1.181-1.181 1.181H4.545c-.709 0-1.181-.472-1.181-1.181V4.545c0-.709.472-1.181 1.182-1.181h8.272c.71 0 1.182-.473 1.182-1.182C14 1.472 13.527 1 12.818 1H4.546C2.536 1 1 2.536 1 4.546v18.909C1 25.464 2.536 27 4.546 27h18.909C25.464 27 27 25.464 27 23.455v-8.273c0-.71-.473-1.182-1.182-1.182z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M15.214 12.452l2.72-.068 7.263-7.263-2.652-2.651-7.263 7.263-.068 2.72zm-.075 2.253a2.125 2.125 0 01-2.178-2.178l.073-2.9a2.125 2.125 0 01.622-1.45L21.042.79c.83-.83 2.176-.83 3.006 0l2.828 2.829c.83.83.83 2.175 0 3.005L19.49 14.01a2.125 2.125 0 01-1.45.621l-2.9.073z\" clip-rule=\"evenodd\"/><circle cx=\"20\" cy=\"19\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});