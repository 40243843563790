define("@latermedia/ember-later-analytics/components/ui/media-kit/best-performing-reels", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @bestPerformingReels as |reel|}}
    <Ui::MediaKit::ReelCardItem
      @isLoading={{is-loading @state}}
      @reel={{reel}}
      @isReport={{@isReport}}
    />
  {{/each}}
  {{#if @dataNotAvailable}}
    <Ui::States::NotFound @className="tRE--cardOverlay"/>
  {{/if}}
  
  */
  {
    "id": "LLvJlNDA",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@isLoading\",\"@reel\",\"@isReport\"],[[28,[37,3],[[30,3]],null],[30,2],[30,4]]],null],[1,\"\\n\"]],[2]],null],[41,[30,5],[[[1,\"  \"],[8,[39,5],null,[[\"@className\"],[\"tRE--cardOverlay\"]],null],[1,\"\\n\"]],[]],null]],[\"@bestPerformingReels\",\"reel\",\"@state\",\"@isReport\",\"@dataNotAvailable\"],false,[\"each\",\"-track-array\",\"ui/media-kit/reel-card-item\",\"is-loading\",\"if\",\"ui/states/not-found\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/media-kit/best-performing-reels.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});