define("@latermedia/ember-later-analytics/models/analytics-media-kit", ["exports", "@latermedia/ember-later-analytics/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AnalyticsMediaKitModel {
    constructor(mediaKit) {
      _defineProperty(this, "defaultAvatarUrl", _constants.PLACEHOLDER_URL);

      _defineProperty(this, "about", void 0);

      _defineProperty(this, "avatarUrl", void 0);

      _defineProperty(this, "name", void 0);

      _defineProperty(this, "contactInfo", void 0);

      _defineProperty(this, "website", void 0);

      _defineProperty(this, "industry", void 0);

      _defineProperty(this, "location", void 0);

      _defineProperty(this, "socialPlatforms", void 0);

      _defineProperty(this, "pastCollabs", void 0);

      _defineProperty(this, "rates", void 0);

      _defineProperty(this, "otherTypesOfCollaboration", void 0);

      _defineProperty(this, "customizationBlocks", void 0);

      _defineProperty(this, "theme", void 0);

      this.avatarUrl = mediaKit.avatar_url || this.defaultAvatarUrl;
      this.name = mediaKit.name;
      this.contactInfo = mediaKit.contact_info;
      this.website = mediaKit.website;
      this.industry = mediaKit.industry;
      this.location = mediaKit.location;
      this.about = mediaKit.about;
      this.socialPlatforms = mediaKit.social_platforms;
      this.pastCollabs = mediaKit.past_collabs;
      this.rates = mediaKit.rates;
      this.otherTypesOfCollaboration = mediaKit.other_types_of_collaboration;
      this.theme = mediaKit.theme;
      this.customizationBlocks = mediaKit.customization_blocks;
    }

  }

  _exports.default = AnalyticsMediaKitModel;
});