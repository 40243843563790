define("ember-svg-jar/inlined/lib-multi-item-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.5 7v14a3 3 0 01-3 3H0v-2h1.5a1 1 0 001-1V7a1 1 0 00-1-1H0V4h1.5a3 3 0 013 3zm19 0v14a3 3 0 003 3H28v-2h-1.5a1 1 0 01-1-1V7a1 1 0 011-1H28V4h-1.5a3 3 0 00-3 3z\"/><path fill-rule=\"evenodd\" d=\"M18 6H9a1 1 0 00-1 1v14a1 1 0 001 1h9a1 1 0 001-1V7a1 1 0 00-1-1zM9 4a3 3 0 00-3 3v14a3 3 0 003 3h9a3 3 0 003-3V7a3 3 0 00-3-3H9z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M12 10a1 1 0 100 2 1 1 0 000-2zm3 1a3 3 0 11-6 0 3 3 0 016 0zm-.357 4.277a1 1 0 011.38 0l4.668 4.454-1.381 1.447-3.977-3.796-6.643 6.341-1.38-1.446 7.333-7z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});