define("ember-svg-jar/inlined/video-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.1 10.1l5.6 3.2c.5.3.5 1 0 1.3l-5.6 3.2c-.5.3-1-.1-1-.7v-6.5c-.1-.4.5-.8 1-.5zM28 6v20c0 .4-.1.8-.3 1.1-.1.2-.3.4-.5.5-.4.3-.8.4-1.2.4H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h24c1.1 0 2 .9 2 2v4zm-2.2 0H2.2v16h23.5V6h.1z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});