define("ember-svg-jar/inlined/category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#a)\"><path fill-rule=\"evenodd\" d=\"M14.815 7.185a6.25 6.25 0 1112.5 0 6.25 6.25 0 01-12.5 0zm6.25 4a4 4 0 110-8 4 4 0 010 8zm-20.25-9a1 1 0 011-1h10a1 1 0 011 1v10a1 1 0 01-1 1h-10a1 1 0 01-1-1v-10zm2.25 8.75v-7.5h7.5v7.5h-7.5z\" clip-rule=\"evenodd\"/><path d=\"M2.61 15.14l4.205 4.204 4.204-4.205a1.125 1.125 0 011.591 1.591l-4.204 4.205 4.204 4.205a1.125 1.125 0 11-1.59 1.59l-4.205-4.204L2.61 26.73a1.125 1.125 0 01-1.59-1.59l4.204-4.205-4.205-4.204a1.125 1.125 0 011.591-1.592z\"/><path fill-rule=\"evenodd\" d=\"M22.68 16.006c-.805-1.477-2.925-1.477-3.73 0l-4.316 7.912c-.772 1.416.253 3.142 1.866 3.142h8.63c1.614 0 2.638-1.726 1.866-3.142l-4.315-7.912zm-5.97 8.804l4.105-7.526 4.105 7.526h-8.21z\" clip-rule=\"evenodd\"/></g><defs><clipPath id=\"a\"><path d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});