define("ember-svg-jar/inlined/ig-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M25.5 0H2a2 2 0 00-2 2v24a2 2 0 002 2h24a2 2 0 002-2V2a2 2 0 00-2-2zM17 2.5v6h-6v-6zm0 8.5v6h-6v-6zM2.5 2.5h6v6h-6zm0 8.5h6v6h-6zm0 14.5v-6h6v6zm8.5 0v-6h6v6zm14.5 0h-6v-6h6zm0-8.5h-6v-6h6zm0-8.5h-6v-6h6z\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});