define("ember-svg-jar/inlined/ai", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.987 3.125l.726-2.9c.074-.3.5-.3.574 0l.726 2.9c.106.425.437.756.862.862l2.9.726c.3.074.3.5 0 .574l-2.9.726a1.185 1.185 0 00-.862.862l-.726 2.9c-.074.3-.5.3-.574 0l-.726-2.9a1.185 1.185 0 00-.862-.862l-2.9-.726c-.3-.074-.3-.5 0-.574l2.9-.726c.425-.106.756-.437.862-.862zm-4.822 9.312l.312-1.248c.063-.252.422-.252.485 0l.312 1.248a1 1 0 00.727.728l1.248.312c.253.063.253.422 0 .485l-1.248.312a1 1 0 00-.727.727l-.312 1.248c-.063.253-.422.253-.485 0l-.312-1.248a1 1 0 00-.728-.727L.19 13.962c-.252-.063-.252-.422 0-.485l1.248-.312a1 1 0 00.728-.728z\"/><path fill-rule=\"evenodd\" d=\"M10.676 25.76a1 1 0 00.707-.293L24.98 11.869c1.07-1.29.96-3.068-.068-4.096-1.027-1.027-2.804-1.137-4.096-.067L7.22 21.303a1 1 0 00-.293.707l-.001 2.75a1 1 0 001 1.001l2.75-.001zM19.29 6.05L4.969 20.37a1 1 0 00-.293.707l-.003 5.935a1 1 0 001 1l5.935-.003a1 1 0 00.707-.293l14.321-14.32c1.836-2.125 1.8-5.28-.133-7.214-1.933-1.933-5.088-1.968-7.212-.132z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "29",
      "fill": "currentColor",
      "viewBox": "0 0 28 29"
    }
  };
  _exports.default = _default;
});