define("ember-svg-jar/inlined/credit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.426 11.498a2.07 2.07 0 011.033-.7V17a2.072 2.072 0 01-1.024-.689c-.433-.53-.65-1.333-.65-2.412v-.019c0-1.059.213-1.853.64-2.382z\"/><path clip-rule=\"evenodd\" d=\"M11.764 27.425a9.663 9.663 0 01-.264.004c-6.351 0-11.5-6.14-11.5-13.715C0 6.14 5.149 0 11.5 0c.621 0 1.23.059 1.825.172C13.952.059 14.595 0 15.25 0 22.015 0 27.5 6.268 27.5 14s-5.485 14-12.25 14c-1.211 0-2.381-.2-3.486-.575zm8.017-23.227C21.774 6.664 23 10.018 23 13.714c0 4.275-1.64 8.093-4.21 10.608 3.605-1.616 6.158-5.628 6.158-10.322 0-4.25-2.094-7.942-5.167-9.802zm-7.058 15.979v-.899c.714-.052 1.352-.211 1.913-.479a4.07 4.07 0 001.665-1.397c.415-.6.648-1.308.699-2.125a.034.034 0 00-.031-.018l-2.537.009a.028.028 0 00-.028.024c-.084.574-.313 1.018-.686 1.335a2.01 2.01 0 01-.995.44v-6.332c.403.072.738.233 1.004.485.374.342.603.79.687 1.345a.041.041 0 00.012.024h2.533a.09.09 0 00.041-.01 4.252 4.252 0 00-.68-2.096c-.395-.612-.947-1.094-1.655-1.445-.565-.287-1.212-.46-1.942-.515v-.891a.632.632 0 00-1.264 0v.907a5.162 5.162 0 00-2.028.624 4.507 4.507 0 00-1.8 1.856C7.212 11.823 7 12.777 7 13.88v.02c0 1.122.21 2.086.632 2.89a4.49 4.49 0 001.808 1.856 5.27 5.27 0 002.02.613v.918a.632.632 0 001.262 0z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});