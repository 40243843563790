define("ember-svg-jar/inlined/delete-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14 .5C6.514.5.5 6.514.5 14S6.514 27.5 14 27.5 27.5 21.486 27.5 14 21.486.5 14 .5zm0 24.545A11 11 0 012.955 14 11 11 0 0114 2.955 11 11 0 0125.045 14 11 11 0 0114 25.045zm4.295-15.34a1.186 1.186 0 00-1.718 0L14 12.282l-2.577-2.577a1.186 1.186 0 00-1.718 0c-.491.49-.491 1.227 0 1.718L12.282 14l-2.577 2.577c-.491.491-.491 1.228 0 1.718.49.491 1.227.491 1.718 0L14 15.718l2.577 2.577c.491.491 1.228.491 1.718 0 .491-.49.491-1.227 0-1.718L15.718 14l2.577-2.577c.491-.491.491-1.228 0-1.718z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});