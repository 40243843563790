define("ember-svg-jar/inlined/gdrive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M26.865 17.002L18.244 2.007H9.747l8.622 14.995h8.496zm-15.744 1.25l-4.248 7.497h16.369l4.248-7.498H11.121zM8.622 3.881L.5 18.252l4.248 7.497 8.247-14.37-4.373-7.497z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});