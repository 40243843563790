define("ember-svg-jar/inlined/not-available", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10 5a5 5 0 10-10.001.001A5 5 0 0010 5zM8.696 5c0 .783-.24 1.5-.674 2.109l-5.13-5.13A3.585 3.585 0 015 1.303 3.693 3.693 0 018.696 5zM1.304 5c0-.783.24-1.5.674-2.109l5.13 5.13c-.608.414-1.325.675-2.108.675A3.693 3.693 0 011.304 5z\" fill=\"#7B8994\"/>",
    "attrs": {
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});