define("@latermedia/ember-later-analytics/components/ui/story-card-item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="o--cardContainer tRE--cardContainer--tall"
    data-test-id="story-card-item"
    data-media-id={{@story.id}}
    data-platform-link={{@story.link}}
  >
    <BestPerformingMedia::Item
      @media={{@story}}
      @isLoading={{@isLoading}}
      @handleError={{@handleError}}
      @isReport={{@isReport}}
    >
      <BestPerformingMedia::Number
        @isLoading={{@isLoading}}
        @showLoadingState={{true}}
        @text={{t "best_stories.impressions"}}
        @number={{@story.impressionsCount}}
        @isReport={{@isReport}}
      />
      <BestPerformingMedia::Number
        @isLoading={{@isLoading}}
        @showLoadingState={{true}}
        @text={{t "best_stories.reach"}}
        @number={{@story.reachCount}}
        @isReport={{@isReport}}
      />
    </BestPerformingMedia::Item>
  </div>
  
  */
  {
    "id": "DhQlk3Nq",
    "block": "[[[10,0],[14,0,\"o--cardContainer tRE--cardContainer--tall\"],[14,\"data-test-id\",\"story-card-item\"],[15,\"data-media-id\",[30,1,[\"id\"]]],[15,\"data-platform-link\",[30,1,[\"link\"]]],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@media\",\"@isLoading\",\"@handleError\",\"@isReport\"],[[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@isLoading\",\"@showLoadingState\",\"@text\",\"@number\",\"@isReport\"],[[30,2],true,[28,[37,2],[\"best_stories.impressions\"],null],[30,1,[\"impressionsCount\"]],[30,4]]],null],[1,\"\\n    \"],[8,[39,1],null,[[\"@isLoading\",\"@showLoadingState\",\"@text\",\"@number\",\"@isReport\"],[[30,2],true,[28,[37,2],[\"best_stories.reach\"],null],[30,1,[\"reachCount\"]],[30,4]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@story\",\"@isLoading\",\"@handleError\",\"@isReport\"],false,[\"best-performing-media/item\",\"best-performing-media/number\",\"t\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/ui/story-card-item.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});