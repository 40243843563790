define("ember-svg-jar/inlined/bttp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14 27.758c-.002 0-10.496-.72-10.5-8.698-.003-6.897 7.36-15.776 9.756-18.483a.998.998 0 01.743-.331.999.999 0 01.741.334c2.392 2.729 9.763 11.687 9.76 18.48-.004 8.236-10.5 8.698-10.5 8.698zm-.439-11.207a.533.533 0 01.862 0c.633.885 1.297 1.91 1.843 2.975.577 1.127 1.053 2.291 1.052 3.348-.001 1.074-.745 1.591-1.421 1.89a6.451 6.451 0 01-1.864.484h-.007a6.86 6.86 0 01-1.9-.528c-.676-.307-1.443-.806-1.444-1.846-.001-1.075.473-2.247 1.051-3.377.541-1.058 1.199-2.071 1.828-2.946zm6.237 6.786c.013-.149.02-.303.02-.462.002-3.524-3.486-8.085-5.061-9.967a.994.994 0 00-1.517-.003c-1.578 1.867-5.06 6.394-5.058 9.97 0 .119.004.236.012.348-1.22-.884-2.193-2.187-2.194-4.165-.001-2.385 1.082-5.024 2.437-7.506 1.592-2.918 3.607-5.611 5.133-7.482a.542.542 0 01.842.002c1.524 1.883 3.55 4.603 5.148 7.532 1.355 2.482 2.441 5.107 2.44 7.454-.001 2.057-.976 3.392-2.202 4.279z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});