define("ember-svg-jar/inlined/trim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.33 17.132a4.229 4.229 0 017.97-2.624l4.226-.82 2.373-12.185a1.048 1.048 0 112.059.4l-1.9 9.796.602-.62a1 1 0 111.433 1.395l-.393.404 9.408-1.824c.528-.103 1.04.21 1.199.71l.03.119c.11.568-.26 1.119-.83 1.229l-12.185 2.373-.818 4.227a4.229 4.229 0 11-2.073-.403l.66-3.388-3.389.66a4.229 4.229 0 01-3.717 3.943l-.226.021A4.229 4.229 0 01.33 17.132zm9.64 7.482a2.088 2.088 0 103.463-2.336 2.088 2.088 0 00-3.462 2.336zm9.176-17.119A1 1 0 1120.58 8.89l-1.744 1.792a1 1 0 11-1.433-1.394l1.743-1.793zm3.486-3.584a1 1 0 011.434 1.395l-1.743 1.792a1 1 0 11-1.434-1.395l1.743-1.792zM26.118.327a1 1 0 011.434 1.394l-1.743 1.792a1 1 0 01-1.434-1.394L26.118.327zM5.733 14.577a2.088 2.088 0 10-2.335 3.463 2.088 2.088 0 002.335-3.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});