define("@latermedia/ember-later-analytics/components/best-performing-media/meter", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <div class={{concat (if @isReport "tRE" "tAN") "--card__title"}}>
      {{#if @isLoading}}
        <div class="cCH--loading cCH--loading__block__sm"></div>
      {{else}}
        {{@title}}
      {{/if}}
    </div>
  
    <div class="cCH--meter u--m__b">
      <div class="cCH--meter__reading">
        {{#if @isLoading}}
          <div class="cCH--loading cCH--loading__block__sm"></div>
        {{else}}
          <span
            class="cCH--meter__value
              {{if @isLoading "u--text--light"}}"
          >
            {{@value}}
          </span>
        {{/if}}
      </div>
  
      <div class="cCH--meter__bar">
        {{#if @isLoading}}
          <div class="cCH--loading cCH--loading__block__xs"></div>
        {{else}}
          <span
            class="cCH--meter__gauge"
            style={{@widthAttr}}
          ></span>
        {{/if}}
      </div>
    </div>
  </div>
  */
  {
    "id": "yXmUCO1w",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[15,0,[28,[37,0],[[52,[30,2],\"tRE\",\"tAN\"],\"--card__title\"],null]],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[10,0],[14,0,\"cCH--loading cCH--loading__block__sm\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,4]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"cCH--meter u--m__b\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"cCH--meter__reading\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[10,0],[14,0,\"cCH--loading cCH--loading__block__sm\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[15,0,[29,[\"cCH--meter__value\\n            \",[52,[30,3],\"u--text--light\"]]]],[12],[1,\"\\n          \"],[1,[30,5]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"cCH--meter__bar\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[10,0],[14,0,\"cCH--loading cCH--loading__block__xs\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"cCH--meter__gauge\"],[15,5,[30,6]],[12],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@isReport\",\"@isLoading\",\"@title\",\"@value\",\"@widthAttr\"],false,[\"concat\",\"if\"]]",
    "moduleName": "@latermedia/ember-later-analytics/components/best-performing-media/meter.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});