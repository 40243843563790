define("ember-svg-jar/inlined/danger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M25.486 9.512a.69.69 0 00-.202-.487l-6.346-6.347a.69.69 0 00-.488-.201H9.475a.69.69 0 00-.487.201L2.64 9.025a.69.69 0 00-.202.487v8.975a.69.69 0 00.202.488l6.347 6.347a.69.69 0 00.487.201h8.975a.69.69 0 00.488-.201l6.346-6.347a.69.69 0 00.202-.488V9.512zm2.477-.069v9.113a3 3 0 01-.879 2.122L20.64 27.12a3 3 0 01-2.12.879H9.406a3 3 0 01-2.122-.879L.842 20.678a3 3 0 01-.88-2.122V9.443a3 3 0 01.88-2.12L7.285.878A3 3 0 019.407 0h9.112a3 3 0 012.121.879l6.444 6.443a3 3 0 01.879 2.121zM15.334 7.827v7.653a1.371 1.371 0 11-2.742 0V7.827a1.371 1.371 0 012.742 0zm-1.371 10.579c.848 0 1.555.697 1.555 1.574 0 .876-.707 1.564-1.555 1.564a1.553 1.553 0 01-1.555-1.564 1.56 1.56 0 011.555-1.574z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});