define("ember-svg-jar/inlined/credit-pack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.398 18.148a1.45 1.45 0 01.724-.49V22a1.45 1.45 0 01-.717-.482c-.304-.371-.456-.934-.456-1.688v-.014c0-.741.15-1.297.45-1.668z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.5 26a6.5 6.5 0 100-13 6.5 6.5 0 000 13zm.506-1.776v-.63c.5-.036.947-.147 1.34-.335a2.85 2.85 0 001.165-.978c.29-.42.453-.915.489-1.487a.024.024 0 00-.022-.013l-1.775.007c-.003 0-.006 0-.01.002a.02.02 0 00-.01.015c-.06.401-.22.712-.48.934-.19.16-.422.262-.697.308v-4.432c.283.05.517.163.703.34.261.238.422.552.481.94 0 .007.004.013.008.017h1.774c.01 0 .02-.002.028-.007a2.977 2.977 0 00-.476-1.467 2.85 2.85 0 00-1.158-1.011 3.573 3.573 0 00-1.36-.361v-.624a.442.442 0 10-.884 0v.635c-.53.055-1.003.201-1.42.437-.54.303-.96.737-1.26 1.3-.295.562-.442 1.23-.442 2.002v.014c0 .786.147 1.46.442 2.023.3.562.721.995 1.266 1.3.417.23.888.373 1.414.428v.643a.442.442 0 10.884 0z\" fill=\"inherit\"/><path d=\"M17.126 22.576c6.273 0 10.126-1.836 10.126-3.15v-3.12c-2.104 1.498-6.2 2.29-10.126 2.29h-.173a8.464 8.464 0 00-.295-1.49c.116.03.267.046.468.046 6.273 0 10.126-1.836 10.126-3.15v-3.123c-2.104 1.5-6.2 2.293-10.126 2.293-1.338 0-2.3-.078-3.206-.22a8.5 8.5 0 10-2.354 14.478c1.707.244 4.34.57 5.56.57 6.273 0 10.126-1.836 10.126-3.15v-3.12c-2.104 1.498-6.2 2.29-10.126 2.29a30.724 30.724 0 00-1.43.006 8.47 8.47 0 00.718-1.418c.117-.018.325-.032.712-.032zM16 19.5a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z\"/><path d=\"M17.126 11.728c6.273 0 10.126-1.836 10.126-3.15v-3.12c-2.104 1.498-6.2 2.29-10.126 2.29-3.93 0-8.022-.792-10.126-2.29v3.12c0 1.314 3.853 3.15 10.126 3.15z\"/><path d=\"M27.252 3.15c0-1.314-3.853-3.15-10.126-3.15C10.85 0 7 1.836 7 3.15c0 1.318 3.853 3.154 10.126 3.154s10.126-1.836 10.126-3.153z\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});