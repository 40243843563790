define("ember-svg-jar/inlined/audience", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14 7.75a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zM14 10a4 4 0 100-8 4 4 0 000 8zm9 .75a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zM23 13a4 4 0 100-8 4 4 0 000 8zM5 10.75a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zM5 13a4 4 0 100-8 4 4 0 000 8z\" clip-rule=\"evenodd\"/><path d=\"M19.875 26a1.125 1.125 0 01-1.125-1.125V19a4.75 4.75 0 10-9.5 0v5.875a1.125 1.125 0 01-2.25 0V19a7 7 0 1114 0v5.875c0 .621-.504 1.125-1.125 1.125zm5.875-1.125a1.125 1.125 0 002.25 0V20a5 5 0 00-6.873-4.637 7.9 7.9 0 01.732 2.134A2.75 2.75 0 0125.75 20v4.875zM1.125 26A1.125 1.125 0 010 24.875V20a5 5 0 016.873-4.637 7.943 7.943 0 00-.732 2.134A2.75 2.75 0 002.25 20v4.875A1.127 1.127 0 011.124 26z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});