define("ember-svg-jar/inlined/duet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_1534_2721)\"><path d=\"M21 10.065c-.604 0-1.094.49-1.094 1.094v3.062a1.094 1.094 0 102.188 0V11.16c0-.604-.49-1.094-1.094-1.094zm-5.469 1.094a1.094 1.094 0 112.188 0v3.062a1.094 1.094 0 11-2.188 0V11.16z\"/><path fill-rule=\"evenodd\" d=\"M14 6.175a9.187 9.187 0 100 15.655 9.187 9.187 0 100-15.655zM9.187 21.002a6.973 6.973 0 002.975-.661 9.156 9.156 0 01-2.537-6.339c0-2.457.965-4.69 2.537-6.338a7 7 0 10-2.974 13.338zm16.626-7a7 7 0 11-14 0 7 7 0 0114 0z\" clip-rule=\"evenodd\"/></g><defs><clipPath id=\"clip0_1534_2721\"><path d=\"M0 0h28v28H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});